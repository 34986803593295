<template>
  <div class="xiangqingBox">
    <div class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>基本信息</span>
        <div class="xian"></div>
      </div>
      <div class="xiangxiList" v-for="(item, index) in details" :key="index">
        <van-cell-group>
          <van-field
            :label="item.title"
            :value="item.con"
            input-align="right"
            readonly
          />
        </van-cell-group>
        <div class="xian"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    details: function() {
      let info = this.$store.state.perilInfo;
      console.log(info);
      // debugger
      return [
        {
          title: "分类",
          con: "公共安全风险",
        },
        {
          title: "风险隐患状况",
          con: "xxxxxxxxxx",
        },
        {
          title: "涉及人数",
          con: "2人",
        },
        {
          title: "包案领导",
          con: "xxx",
        },
        {
          title: "职务",
          con: "xxx",
        },
        {
          title: "联系电话",
          con: "123-5678-21321",
        },
        {
          title: "化解防控措施",
          con: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
        },
        {
          title: "化解时间",
          con: "2022年4月20日",
        },
        {
          title: "是否化解",
          con: "已化解",
        },
        {
          title: "哪级交办",
          con: "xxxxxxxxxxxx",
        },
        {
          title: "是否新增",
          con: "是",
        },
      ]
    }
  }
};
</script>
<style lang="less" scoped>
@import url('~@/assets/less/conment.less');

.xiangqingBox {
  margin: 10px 10px;
  .jiben {
    padding-top: 27px;
    .xiangxiList {
      .van-cell-group {
        width: 100%;
      }
    }
  }
}
</style>
