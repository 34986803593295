<template>
  <div class="importantFormBox" @scroll="onScroll">
    <div class="search" ref="search">
      <van-field
        v-model="queryParams.condition"
        placeholder="输入搜索内容"
        type="text"
        input-align="left"
      >
        <template slot="left-icon">
          <img
            style="width: 15px; height: 15px"
            :src="searchIcon"
            alt=""
            class="searchInput"
          />
        </template>
        <template slot="right-icon">
          <div @click="clickSearch">搜索</div>
        </template>
      </van-field>
    </div>
    <div
      class="conBox"
      ref="conBox"
      v-for="(item, index) in dataList"
      :key="index"
    >
      <div class="title">
        <span class="word">{{ item.monitorTerm }} </span>
        <div class="Icon">
          <img :src="jiankongIcon" @click="refresh" alt="" />
          <img
            @click="shoucang(item, index)"
            :src="item.isFocus == 1 ? startLiang : startIcon"
            alt=""
          />
        </div>
      </div>
      <div class="video">
        <!-- 监控视频         -->
        <!-- <flvPlayer ref="flvPlayer" :videoURL="item.wsFlv"></flvPlayer> -->
        <LivePlayer
          v-if="viewVideo && item.show"
          ref="LivePlayer"
          :videoURL="item.hls"
        ></LivePlayer>
        <div v-else class="no-auth-video">
          <img src="@/assets/img/home/web-img.png" class="video-img" />
          <div>暂无访问权限</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/home/index";
import flvPlayer from "../../components/monitor/flvPlayer.vue";
import LivePlayer from "../../components/monitor/LivePlayer.vue";
import request from "@/utils/request";
export default {
  components: {
    flvPlayer,
    LivePlayer,
  },
  data() {
    return {
      viewVideo: localStorage.getItem("viewVideo") == "true" ? true : false,
      queryParams: {
        condition: "",
        facilityId: null,
        scenicSpotId: null,
        type: 2,
      },
      dataList: [],

      searchIcon: require("../../assets/img/important/search.png"),
      jiankongIcon: require("../../assets/img/important/jiankong.png"),
      startIcon: require("../../assets/img/important/start.png"),
      startLiang: require("../../assets/img/important/startliang.png"),
      maxNum: 4,
    };
  },
  mounted() {
    this.allMonitorInfo();
  },
  methods: {
    //重点关注
    allMonitorInfo() {
      const scenicSpotId = localStorage.getItem("scenicSpotId");
      this.queryParams.scenicSpotId = scenicSpotId;
      request
        .post("/onlineMonitor/getOnlineMonitor", this.queryParams)
        .then((res) => {
          console.log("res", res);
          if (res.code == "200" && res.success) {
            const { equipmentVOS } = res.data;
            this.dataList = equipmentVOS;
            this.dataList.forEach((item, index) => {
              item.show = index < this.maxNum;
            });
          }
        });
    },
    shoucang(item, index) {
      //将设备加入收藏夹 1收藏 0取消收藏
      let id = item.id;
      request.post("/onlineMonitor/editFocus", { id: id }).then((res) => {
        if (res.code == 200 && res.success) {
          if (item.isFocus == 0) {
            this.$toast({
              message: "添加收藏",
              icon: this.startLiang,
            });
          } else {
            this.$toast({
              message: "取消收藏",
              icon: this.startIcon,
            });
          }
          this.allMonitorInfo();
        }
      });
    },
    // 刷新
    refresh() {
      this.allMonitorInfo();
    },
    clickSearch() {
      this.allMonitorInfo();
    },
    onScroll(e) {
      const distance = e.target.scrollTop - this.$refs.conBox[0].offsetHeight;
      console.log(distance - 80, "  ", this.$refs.conBox[0].offsetHeight);
      const startIndex = Math.floor(
        distance / this.$refs.conBox[0].offsetHeight
      );
      for (let i = 0; i < this.dataList.length; i++) {
        let item = this.dataList[i];
        let show = true;
        if (i < startIndex || i > startIndex + this.maxNum) {
          show = false;
        }
        if (item.show != show) {
          item.show = show;
          this.$set(this.dataList, i, item);
        }
      }
      console.log(startIndex, "distance", this.dataList);
    },
  },
};
</script>
<style lang="less" scoped>
@import url("~@/assets/less/conment.less");
.importantFormBox {
  margin: 10px 10px;
  height: calc(100% - 20px);
  overflow: auto;
  .search {
    margin-top: 10px;
    height: 40px;
    width: 100%;
    .liangbian();
    .van-field {
      border-radius: 18px;
      align-items: center;
    }
  }
  .conBox {
    height: 232px;
    background: #ffffff;
    border-radius: 11px;
    padding: 10px 10px;
    margin-top: 12px;
    .shangxiafeng();
    .title {
      height: 18px;
      .liangbian();
      .word {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #363a44;
      }
      .Icon {
        width: 47px;
        .liangbian();
        img {
          &:first-child {
            width: 15px;
          }
          width: 18px;
          height: 18px;
        }
      }
    }
    .video {
      height: 177px;
      border-radius: 9px;
      video {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.no-auth-video {
  img.video-img {
    display: block;
    width: 220px;
    // height: 82px;
    margin: 20px auto;
  }
  div {
    font-size: 12px;
    text-align: center;
    font-weight: normal;
    color: #5bb8f8;
    line-height: 25px;
  }
}
</style>
