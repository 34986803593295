<template>
  <div class="bigBox">
    <div class="selectBox">
      <div class="jingqu">
        <span>请选择景区</span>
        <div class="jiao"></div>
      </div>
      <div class="sheshi">
        <span>请选择设施</span>
        <div class="jiao"></div>
      </div>
      <div class="map">
        <img :src="map" alt="" />
      </div>
    </div>
    <div class="liuyanBox">
      <van-field v-model="message" rows="1" autosize type="textarea" placeholder="请详细描述事件发生地点、什么事、期望怎么解 决等等..." />
      <div class="img">
        <van-uploader v-model="fileList" :after-read="afterRead" />
      </div>
    </div>
    <div class="jingquBox">
      <van-cell title="浙江省杭州市桐庐县桐庐瑶琳镇东琳村" :icon="weizhi">
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <template #right-icon>
          <span class="bixuan">必选</span>
          <img :src="jiantou" class="jiantou" alt="" />
        </template>
      </van-cell>
    </div>
    <div class="shifouBox">
      <van-cell title="是否公开" :icon="shifou">
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <template #right-icon>
          <span class="kejian">公众可见</span>
          <van-switch v-model="checked" size="24" />
        </template>
      </van-cell>
    </div>
    <van-button round type="info" size="50px">确认提交</van-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      fileList: [
        // Uploader 根据文件后缀来判断是否为图片文件
        // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
      ],
      weizhi: require('@/assets/img/zlb/dingwei.png'),
      jiantou: require('@/assets/img/zlb/jiantou.png'),
      shifou: require('@/assets/img/zlb/shifou.png'),
      checked: false,
      message: '',
      map: require('@/assets/img/zlb/map.png')
    };
  },
  methods: {
    afterRead (file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
    }
  }
};
</script>
<style lang="less" scoped>
.bigBox {
  padding: 12px 12px;
  display: flex;
  flex-direction: column;
  .selectBox {
    height: 52px;
    background: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .jingqu {
      height: 17px;
      width: 40%;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }
      .jiao {
        height: 6px;
        width: 8px;
        align-self: flex-end;
        background-size: 100% 100%;
        background-image: url("~@/assets/img/zlb/youjiao.png");
      }
    }
    .sheshi {
      height: 17px;
      width: 40%;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }
      .jiao {
        height: 6px;
        width: 8px;
        background-image: url("~@/assets/img/zlb/youjiao.png");
        background-size: 100% 100%;
        align-self: flex-end;
      }
    }
    .map {
      height: 22px;
      width: 34px;
      display: flex;
      border-left: 1px solid #c0c0c0;
      justify-content: space-around;
      img {
        width: 19px;
        height: 19px;
        align-self: flex-end;
      }
    }
  }
  .liuyanBox {
    .van-cell {
      height: 55%;
    }
    margin-top: 12px;
    height: 237px;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 18px;
    padding-right: 12px;
  }
  .jingquBox {
    margin-top: 12px;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
    border-radius: 6px;
    height: 78px;
    display: flex;

    .van-cell {
      align-items: center;
      .van-cell__left-icon {
        margin-top: 6px;
      }
    }
    span {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #363a44;
      line-height: 26px;
    }
    .bixuan {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #b3b5b9;
      line-height: 26px;
      margin-right: 10px;
    }
    .jiantou {
      height: 16px;
      width: 9px;
    }
  }
  .shifouBox {
    margin-top: 12px;
    height: 57px;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
    border-radius: 6px;
    display: flex;
    .van-cell {
      align-items: center;
      .van-cell__left-icon {
        margin-top: 6px;
      }
    }
    .kejian {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #b3b5b9;
      line-height: 26px;
      margin-right: 6px;
    }
    span {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #363a44;
      line-height: 26px;
    }
  }
  .van-button {
    margin-top: 12px;
    font-size: 19px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
  }
}
</style>
