import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import zlbHome from '../views/zhelibanPage/homePage.vue' //浙里办首页
import zlbYou from '../views/zhelibanPage/youke.vue'//浙里办游客
import zlbUploade from '../views/zhelibanPage/record.vue' //浙里办上传记录
// import {queue} from '@/utils/zzdmd.js';



import Home from '../views/Home.vue'
import Nav from '../components/nav/nav.vue'
import Patrol from '../views/Patrol.vue'
import Task from '../views/Task.vue'
import My from '../views/My.vue'
import patrolForm from '../views/patrol/Form.vue'
import zhidingForm from '../views/zhiding/Form.vue'
// import zhidingForm from '../views/zhiding/text.vue';
import zhongdianForm from '../views/important/Form.vue'
import safetyForm from '../views/safety/Form.vue'
//任务基本信息页面
import taskxiangqing from '../views/renwu/xiangqing.vue'
//审核任务通过不通过页面
import renwushenhe from '../views/renwu/shenhePage.vue'
//已经审核过的任务看审核详情页面
import renwuyishenhe from '../views/renwu/chakanPage.vue'
import yinhuanForm from '../views/yinhuan/Form.vue'
import yinhuanxiangxi from '../views/yinhuan/xiangqing.vue'
import Homexiangxi from '../views/Homexiangxi/Form.vue'
import xunchashenhe from '../views/patrol/shenheForm.vue';
//地图
// import map from '../views/map/index.vue'
//地图详情
// import mapxiangxi from '../views/map/xiangxi.vue'
import myshenhe from '../views/my/shenhejilu.vue'
//首页景区二级列表跳转后的预警列表页
import homexiangxilist from '../views/Homexiangxi/list.vue'
//我的 发布历史
import fabu from '../views/my/fabu.vue'
//我的 消息
import message from '../views/my/message.vue'
//我的消息详情
import messagexiangxi from '../views/my/messagexiangxi.vue'
//我的 收藏
import shoucang from '../views/my/shoucang.vue';
//我的 景区信息
import jingqu from '../views/my/jingquinformation.vue'
//我的 审批事项详情
import shenpi from '../views/my/shenpi.vue'

//首页设备预警列表
import waringList from '../views/waring/list.vue'
//预警设备详情
import waringForm from '../views/waring/xiangqing.vue'
//预警审核页面
import waringshenhe from '../views/waring/waringshen.vue';
//预警审核已通过页面
import waringchakan from '../views/waring/waringchakan.vue'
//退回页面
import back from '../views/renwu/back.vue'


Vue.use(VueRouter)



const routes = [
  // {
  //   path: '',
  //   redirect: '/home'
  // },
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/nav',
    name: 'Nav',
    component: Nav
  },
  {
    path: '/patrol',
    name: 'Patrol',
    component: Patrol
  },
  {
    path: '/task',
    name: 'Task',
    component: Task
  },
  {
    path: '/my',
    name: 'My',
    component: My
  },
  {
    path: '/patrol/from',
    name: 'patrolForm',
    component: patrolForm
  },
  {
    path: '/zhiding/from',
    name: 'zhidingForm',
    component: zhidingForm
  },
  {
    path: '/important/from',
    name: 'zhongdianForm',
    component: zhongdianForm
  },
  {
    path: '/safety/from',
    name: 'safetyForm',
    component: safetyForm
  },
  {
    path: '/renwu/xiangqing',
    name: 'taskxiangqing',
    component: taskxiangqing
  },
  {
    path: '/yinhuan/form',
    name: 'yinhuanForm',
    component: yinhuanForm
  },
  {
    path: '/yinhuan/xiangqing',
    name: 'yinhuanxiangxi',
    component: yinhuanxiangxi
  },
  {
    path: '/Home/xiangxi',
    name: 'Homexiangxi',
    component: Homexiangxi
  }, {
    path: '/xunchashenhe/',
    name: 'xunchashenhe',
    component: xunchashenhe
  }, 
  // {
  //   path: '/map/index',
  //   name: 'map',
  //   component: map
  // },
  // {
  //   path: '/map/xiangxi',
  //   name: 'mapxiangxi',
  //   component: mapxiangxi
  // },
  {
    path: '/industryMap',
    name: 'industryMap',
    component: () => import('@/views/industryMap/scenesMap.vue'),
  },
  {
    path: '/industryMap/detail',
    name: 'sceneDetail',
    component: () => import('@/views/industryMap/sceneDetail.vue'),
  },
  {
    path: '/oneMap',
    name: 'oneMap',
    component: () => import('@/views/industryMap/oneMap.vue'),
  },
  {
    path: '/oneMap/detail',
    name: 'facilityDetail',
    component: () => import('@/views/industryMap/facilityDetail.vue'),
  },
  {
    path: '/twoMap',
    name: 'twoMap',
    component: () => import('@/views/industryMap/twoMap.vue'),
  },
  {
    path: '/my/shenhejilu',
    name: 'myshenhe',
    component: myshenhe
  }, {
    path: '/Homexiangxi/list',
    name: 'homexiangxilist',
    component: homexiangxilist
  },
  {
    path: '/my/fabu',
    name: 'fabu',
    component: fabu
  }, {
    path: '/my/message',
    name: 'message',
    component: message
  },
  {
    path: '/my/shoucang',
    name: 'shoucang',
    component: shoucang
  },
  {
    path: '/my/jingquinformation',
    name: 'jingqu',
    component: jingqu
  },
  {
    path: '/my/messagexiangxi',
    name: 'messagexiangxi',
    component: messagexiangxi
  },
  {
    path: '/my/shenpi',
    name: 'shenpi',
    component: shenpi
  },
  {
    path: '/renwu/shnhePage',
    name: 'renwushenhe',
    component: renwushenhe
  },
  {
    path: '/renwu/renwuyishenhe',
    name: 'renwuyishenhe',
    component: renwuyishenhe
  },
  {
    path: '/waring/list',
    name: 'waringList',
    component: waringList
  }, {
    path: '/waring/xiangqing',
    name: 'waringForm',
    component: waringForm
  },
  {
    path: '/waring/waringshen',
    name: 'waringshenhe',
    component: waringshenhe
  },
  {
    path: '/waring/waringchakan',
    name: 'waringchakan',
    component: waringchakan
  }, {
    path: '/zhelibanPage/homePage',
    name: 'zlbHome',
    component: zlbHome
  }, {
    path: '/zhelibanPage/record',
    name: 'zlbUploade',
    component: zlbUploade
  },
  {
    path: '/zhelibanPage/youke',
    name: 'zlbYou',
    component: zlbYou
  },
  {
    path: '/renwu/back',
    name: 'back',
    component: back
  }
]
// router.beforeEach((to, from, next) => {
//   let token = sessionStorage.getItem('tongluUser');
//   if (token) {
//     if (to.path === '/') {
//       next({ path: '/location' });
//     } else {
//       next();
//     }
//   } else {
//     if (to.path !== '/') {
//       next({ path: '/' });
//     } else {
//       next();
//     }
//   }

// })
const router = new VueRouter({
  routes
})

router.afterEach(() => {
  // 用户名，用户id
  // queue(lastName, 用户ID)
})


export default router
