<template>
  <div class="bigBox">
    <div class="topBox"></div>
    <div class="middleBox">
      <div class="leftBox">
        <p class="title">游客参与</p>
        <div class="smallBox">
          <span>游客体验高频事项 意见反馈</span>
          <img :src="youke" alt="" />
        </div>
        <van-button
          round
          style="
            width: 60%;
            height: 36px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
          "
          type="info"
          >点击进入</van-button
        >
      </div>
      <div class="rightBox">
        <p class="title">企业运营</p>
        <div class="smallBox">
          <span>提高治理能力防范化解重大风险隐患</span>
          <img :src="qiye" alt="" />
        </div>
        <van-button
          round
          style="
            width: 60%;
            height: 36px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
          "
          type="info"
          >点击进入</van-button
        >
      </div>
    </div>
    <div class="shuoming">
      本服务由浙江政务服务网、桐庐县文广旅体局提供 服务咨询热线：0571-58598070
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      qiye: require('@/assets/img/zlb/qiye.png'),
      youke: require('@/assets/img/zlb/youke.png')
    };
  },
  methods: {}
};
</script>
<style lang="less" scoped>
.bigBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .topBox {
    height: 151px;
    width: 100%;
    background-image: url('~@/assets/img/zlb/top.jpg');
    background-size: 100% 100%;
  }
  .middleBox {
    padding: 0px 7px 0px 7px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    .leftBox,
    .rightBox {
      height: 210px;
      width: 47%;
      background: #ffffff;
      padding: 0px 4px 0px 4px;
      border-radius: 17px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      .title {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #363a44;
      }
      .smallBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 60px;
        background: #f0f8ff;
        border-radius: 3px;
        span {
          font-size: 10px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #686b73;
          width: 70%;
        }
        img {
          height: 39px;
        }

        /deep/ .van-button {
        }
      }
    }
  }
  .shuoming {
    width: 84%;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #363a44;
    line-height: 25px;
    margin-top: 90px;
  }
}
</style>
