<template>
  <div class="HomeBOX">
    <van-pull-refresh
      v-model="isLoading"
      success-text="刷新成功"
      @refresh="onRefresh"
    >
      <div class="HomeTop">
        <van-notice-bar
          color="#1989fa"
          :scrollable="true"
          background="#fff"
          :left-icon="trumpet"
        >
          {{ title }}
        </van-notice-bar>
      </div>
      <div class="thisMonth">
        <div class="thisMonthWaring">
          <div class="top">
            <span>本月预警{{ topData.warningCount }}次</span>
            <div class="xian"></div>
          </div>
          <div class="button">
            <span
              >本月已处理<span class="big"> {{ topData.warningCount2 }} </span
              >次</span
            >
          </div>
        </div>
        <div class="thisMonthTask">
          <div class="top">
            <span>本月任务{{ topData.taskCount }}个</span>
            <div class="xian"></div>
          </div>
          <div class="button">
            <span
              >本月已处理<span class="big"> {{ topData.taskCount2 }} </span
              >个</span
            >
          </div>
        </div>
        <div class="thisMonthExamine">
          <div class="top">
            <span>本月例巡{{ topData.patrolCount }}次</span>
            <div class="xian"></div>
          </div>
          <div class="button">
            <span
              >本月已巡查<span class="big"> {{ topData.patrolCount2 }} </span
              >次</span
            >
          </div>
        </div>
      </div>
      <div class="important">
        <div class="top">
          <div class="title">
            <div class="icon"></div>
            <span>重点关注</span>
          </div>
          <div class="more" @click="skip" v-if="viewVideo" >
            <span>更多</span>
            <div class="back"></div>
          </div>
        </div>
        <div class="importanImg">
          <van-swipe
           
            class="my-swipe"
            indicator-color="white"
            @change="onChange"
          >
            <van-swipe-item v-for="(item, index) in dataList" :key="index">
              <!-- 监控视频 -->
              <!-- <LivePlayer ref="LivePlayer" v-if="$isIOS" :videoURL="item.webrtc"></LivePlayer> -->
              <!-- <flvPlayer ref="flvPlayer" v-if="isShowVideo(index)" :videoURL="item.wsFlv"></flvPlayer> -->

              <template v-if="isShowVideo(index)">
                <EasyPlayer ref="EasyPlayer" v-if="$isIOS" :videoURL="item.webrtc" :img="item.imgAddress"></EasyPlayer>
                <EasyPlayer ref="EasyPlayer" v-else :videoURL="item.wsFlv" :img="item.imgAddress"></EasyPlayer>
              </template>
              <!-- <div style="background-color: black;width: 100%;height: 100%;">
                <EasyPlayer ref="EasyPlayer" v-if="current == index" :videoURL="item.hls" :img="item.imgAddress"></EasyPlayer>
              </div> -->

              <!-- <LivePlayer ref="LivePlayer" v-if="isShowVideo(index)" :videoURL="item.wsFlv" :img="item.imgAddress"></LivePlayer> -->
              <!-- <LivePlayer ref="LivePlayer" v-if="isShowVideo(index)" :videoURL="item.hls" :img="item.imgAddress"></LivePlayer> -->
              <!-- <LivePlayer ref="LivePlayer" v-else :videoURL="null" :img="item.imgAddress"></LivePlayer> -->
            </van-swipe-item>
          </van-swipe>
          <!-- <div v-else class="no-auth-video" >
            <img src="@/assets/img/home/web-img.png" class="video-img" />
            <div>暂无访问权限</div>
          </div> -->
        </div>
      </div>
      <div class="option">
        <div class="scenicArea" @click="map()">
          <div class="back"></div>
          <span>景区地图</span>
        </div>
        <div class="shebei" @click="waring">
          <div class="back"></div>
          <div class="iconwarn" v-if="yujingtotal">
            <van-icon name="warning" color="#FF3A2F" size="18" />
          </div>
          <span>设备预警</span>
        </div>
        <div class="yinhuan" @click="yinhuan()">
          <div class="back"></div>
          <span>排查预警</span>
        </div>
        <!-- <div class="anquan" @click="safetyForm()">
          <div class="back"></div>
          <span>安全评估</span>
        </div> -->
      </div>
      <div class="jingquList">
        <div class="top">
          <div class="title">
            <div class="icon"></div>
            <span>景区列表</span>
          </div>
          <div class="search">
            <van-search
              v-model="form.name"
              shape="round"
              placeholder="请输入搜索关键词"
              @search="clickSearch"
            />
            <!-- <van-field
              v-model="form.name"
              placeholder="搜索景区名称"
              type="text"
              input-align="center"
            >
              <template slot="left-icon">
                <img
                  style="width: 14px; height: 14px"
                  :src="searchIcon"
                  alt=""
                  class="searchInput"
                  @click="clickSearch"
                />
              </template>
            </van-field> -->
          </div>
        </div>
        <div class="listox">
          <div
            class="list"
            v-for="(item, index) in jingList"
            :key="index"
            @click="xiangxi(item.id, item.name)"
          >
            <div class="box">
              <img :src="item.imageUrl" class="jingImg" alt="" />
              <div class="textBox">
                <div class="title">{{ item.name }}</div>
                <div class="shebei" style="margin-top: 10px">
                  设备：<span class="number">{{ item.equipmentCount }}个</span>
                </div>
                <div class="xiangqing">
                  <div style="margin-right: 1px">
                    预警：<span class="number">{{ item.warningCount }}次</span>
                  </div>
                  <div>
                    巡查：<span class="number">{{ item.patrolCount }}次</span>
                  </div>
                  <div>
                    任务：<span class="number">{{ item.taskCount }}次</span>
                  </div>
                </div>
                <div class="sheshi van-ellipsis">设施：{{ item.names }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Nav></Nav>
    </van-pull-refresh>
  </div>
</template>
<script>
import api from "../api/methods";
import api2 from "@/api/home/index";
import api3 from "@/api/waring/index";
import Nav from "@/components/nav/nav.vue";
import request from "@/utils/request";
import flvPlayer from "../components/monitor/flvPlayer.vue";
import LivePlayer from "../components/monitor/LivePlayer.vue";
import EasyPlayer from "../components/monitor/LivePlayer.vue";
export default {
  name: "Home",
  components: {
    Nav,
    flvPlayer,
    LivePlayer,
    EasyPlayer
  },
  data() {
    return {
      viewVideo: localStorage.getItem('viewVideo') == 'true' ? true : false,
      yujingtotal: 0,
      current: 0,
      queryParams: {
        condition: "",
        facilityId: null,
        scenicSpotId: null,
        type: 1,
      },
      form: {
        name: "",
      },
      topData: {},
      jingList: [],
      videoURL: "",
      dataList: [],
      searchIcon: require("../assets/img/home/search.png"),
      //   title: '这是一条防疫的消息,疫情期间注意防控!',
      title: "",
      trumpet: require("../assets/img/home/trumpet.png"),
      importanImg: require("../assets/img/login/background.webp"),
      userName: "admin",
      // userName: 'tth',
      password: "qqqqqq",
      // userName: 'lxj',
      // password: '123456'
      isLoading: false,
    };
  },
  created() {
    // let viewVideo = window.localStorage.getItem('viewVideo');
    // if(viewVideo == 'true') {
    //   this.viewVideo = true;
    // } else {
    //   this.viewVideo = false;
    // }
    // console.log(Boolean(window.localStorage.getItem('viewVideo')))
    // setTimeout(() => {
    //   this.$toast.loading({
    //     message: '加载中...',
    //     forbidClick: true
    //   });
    // }, 500);
  },
  mounted() {
    this.getList();
    this.getAnnouncementInfo();
    this.getTopList();
    this.allMonitorInfo();
    this.getListyujing();
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      // window.addEventListener("popstate", this.goBack, false);
    }
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBack, false);
  },
  methods: {
    isShowVideo(index){
      let len = this.dataList.length,isShow = false
      if(this.current < len - 1 && this.current > 0){
        if(((this.current - 1) == index) || ((this.current + 1) == index) || (this.current == index)){
          return true
        }
      }else if(this.current == len - 1){
        if(((this.current - 1) == index) || (0 == index) || (this.current == index)){
          return true
        }
      }else if(this.current == 0){
        if(((len - 1) == index) || ((this.current + 1) == index) || (this.current == index)){
          return true
        }
      }
      return false
    },
    onRefresh() {
      this.getList();
      this.isLoading = false;
    },
    map() {
      this.$router.push("/industryMap");
    },
    //重点关注
    allMonitorInfo() {
      request
        .post("/onlineMonitor/getOnlineMonitor", this.queryParams)
        .then((res) => {
          if (res.code == "200" && res.success) {
            const { equipmentVOS } = res.data;
            const isFocusdata = equipmentVOS.filter((item) => {
              return item.isFocus == 1;
            });
            // this.videoURL = equipmentVOS[0] && equipmentVOS[0].webrtc
            this.dataList = isFocusdata;
            console.log('4444444444',this.dataList)
          }
        });
    },
    onChange(index) {
      this.current = index;
    },
    //获取景区列表
    getList() {
      api2.list(this.form).then((res) => {
        this.jingList = res.data;
        this.jingList.forEach((item) => {
          if (item.names.length > 16) {
            item.names = item.names.slice(0, 16) + "...";
          }
        });
      });
    },
    //获取预警列表数据
    getListyujing() {
      api3
        .warnList({
          examineState: 1,
        })
        .then((res) => {
          if (res.code == "200") {
            this.yujingtotal = res.total;
          }
        });
    },
    clickSearch() {
      this.getList();
    },
    skip() {
      this.$router.push({
        name: "zhongdianForm",
        query: {
          name: "重点关注",
        },
      });
    },
    safetyForm() {
      this.$router.push({
        name: "safetyForm",
        query: {
          name: "安全评估",
        },
      });
    },
    yinhuan() {
      this.$router.push({
        name: "yinhuanForm",
        query: {
          name: "隐患排查",
        },
      });
    },
    xiangxi(id, name) {
      this.$router.push({
        name: "Homexiangxi",
        query: {
          id: id,
          name: name,
        },
      });
    },
    //预警设备跳转列表
    waring() {
      this.$router.push({
        name: "waringList",
        query: {
          name: name,
        },
      });
    },
    //获取公告
    getAnnouncementInfo() {
      request
        .post("/announcement/list", {
          page: 1,
          pageSize: 1,
          condition: null,
          type: 1,
        })
        .then((res) => {
          this.title = res.data?.rows[0]?.details;
        });
    },
    //头部信息
    getTopList() {
      api2.toplist().then((res) => {
        if (res.code == "200") {
          console.log(res.data);
          this.topData = res.data;
        }
      });
    },
    goBack() {
      console.log("退回");
      this.$router.replace({ path: "/home" });
      console.log(window.history.pushState);
      //replace替换原路由，作用是避免回退死循环
    },
  },
};
</script>
<style lang="less" scoped>
.mar {
  margin-left: 10px;
  margin-right: 10px;
}

.my-swipe .van-swipe-item {
  line-height: 200px;
  height: 200px;
}


.HomeBOX {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: PingFang SC;
  font-weight: 400;

  .HomeTop {
    .mar();
    margin-top: 10px;
    height: 31px;
    display: flex;
    justify-content: center;

    .van-notice-bar {
      width: 90%;
      border-radius: 31px;
    }
  }

  .thisMonth {
    margin-top: 15px;
    height: 61px;
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
    margin-right: 5px;

    .width {
      width: 119px;
      height: 100%;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      color: #fff;
      font-size: 12px;
      padding-left: 7.5px;
      padding-right: 7.5px;
      border-radius: 2px;

      .top {
        height: 18px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .xian {
          width: 31px;
          height: 1px;
          background: #fff;
        }
      }

      .button {
        width: 100%;

        .big {
          min-width: 30px;
          font-size: 16px;
          display: inline-block;
          text-align: center;
        }
      }
    }

    .thisMonthWaring {
      .width();

      background-image: url("../assets/img/home/waring.png");
    }

    .thisMonthTask {
      .width();
      background-image: url("../assets/img/home/taskback.png");
    }

    .thisMonthExamine {
      .width();
      background-image: url("../assets/img/home/examine.png");
    }
  }

  .important {
    .mar();
    margin-top: 10px;
    height: 172px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: PingFang SC;
    font-weight: 400;

    .top {
      display: flex;
      justify-content: space-between;
      margin-left: 5px;
      margin-right: 13px;
      margin-bottom: 10px;

      .title {
        width: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icon {
          width: 12.5px;
          height: 16px;
          background-image: url("../assets/img/home/important.png");
          background-size: 100% 100%;
          margin-top: 3px;
        }

        span {
          font-size: 17px;
          color: #363a44;
        }
      }

      .more {
        width: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          color: #686b73;
          font-size: 12px;
        }

        .back {
          margin-top: 3px;
          height: 10px;
          width: 6px;
          background-image: url("../assets/img/home/more.png");
          background-size: 100% 100%;
        }
      }
    }

    .importanImg {
      width: 100%;
      height: 182px;
      border-radius: 4px;
    }
  }

  .option {
    margin-top: 68px;
    height: 95px;
    background: #fff;
    display: flex;
    justify-content: space-around;
    .mar();
    border-radius: 4px;

    .box {
      width: 49px;
      font-size: 12px;
      color: #363a44;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }

    .boxback {
      background-size: 100% 100%;
      width: 44px;
      height: 44px;
      text-align: center;
    }

    .scenicArea {
      .box();

      > .back {
        .boxback();
        background-image: url("../assets/img/home/ditu.png");
      }
    }

    .shebei {
      position: relative;
      .iconwarn {
        position: absolute;
        top: 10px;
        left: 30px;
      }
      .box();

      > .back {
        .boxback();
        background-image: url("../assets/img/home/shebei.png");
      }
    }

    .yinhuan {
      .box();

      > .back {
        .boxback();
        background-image: url("../assets/img/home/yinhuan.png");
      }
    }

    .anquan {
      .box();

      > .back {
        .boxback();
        background-image: url("../assets/img/home/anquan.png");
      }
    }
  }

  .jingquList {
    margin-top: 15px;
    padding-bottom: 70px;
    .mar();

    .top {
      height: 30px;
      display: flex;
      justify-content: space-between;
      .mar();

      .title {
        width: 93px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icon {
          height: 14px;
          width: 18px;
          background-image: url("../assets/img/home/\jingquList.png");
          background-size: 100% 100%;
        }

        span {
          font-size: 17px;
          color: #363a44;
        }
      }

      .search {
        width: 158px;
        height: 29px;
        .van-search{
          background-color: transparent;
          height: 100%;
          border-radius: 15px;
          padding: 4px 16px !important;
          .van-search__content{
            background-color: #fff;
          }
          .van-search__content{
            padding-left: 0;
          }
        }

        .van-field {
          height: 100%;
          border-radius: 15px;
          padding: 4px 16px !important;
        }
      }
    }

    .listox {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background: #fff;
      border-radius: 4px;
      padding-left: 16px;
      padding-right: 16px;

      > .list {
        height: 93px;
        width: 324px;
        border-bottom: 1px solid #e8e9ec;
        margin-top: 15px;

        &:last-child {
          border-bottom: 0px;
        }

        .box {
          display: flex;

          .jingImg {
            width: 100px;
            height: 80px;
            border-radius: 4px;
          }

          .textBox {
            width: 212px;
            height: 80px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 6px;

            .title {
              height: 15px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #363a44;
            }

            .shebei,
            .xiangqing,
            .sheshi {
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #686b73;

              .number {
                color: #3f8cec;
              }
            }

            .xiangqing {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }

  .nav {
    width: 100%;
  }
}

.no-auth-video {
  img.video-img {
    display: block;
    width: 220px;
    // height: 82px;
    margin: 20px auto;
  }
  div {
    font-size: 12px;
    text-align: center;
    font-weight: normal;
    color: #5BB8F8;
    line-height: 25px;
  }
}
</style>
