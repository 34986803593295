<template>
  <div class="wu">
    <img :src="wu" alt="" />
    <div class="word">
      暂无数据~
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      wu: require('@/assets/img/my/norenwu.png'),
    }
  }
}
</script>

<style lang="less" scoped>
.wu {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  img {
    width: 160px;
    height: 160px;
  }

  .word {
    margin-top: 24px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #b3b5b9;

    span {
      color: #317aed;
    }
  }
}
</style>