import request from '@/api/index';

export default {
  //审核记录
  auditRecord(data) {
    return request.get('auditRecord', data)
  },
  releaseHistory(data) {
    return request.get('releaseHistory', data)
  },
  message(data) {
    return request.get('message', data)
  },
  collectionList(data) {
    return request.get('collectionList', data)
  },
  scenicSpotDetail(data) {
    return request.get('scenicSpotDetail', data)
  },
  announcement(data) {
    return request.get('announcement', data)
  },
  auditNotice(data) {
    return request.get('auditNotice', data)
  },
  myCollection(data) {
    return request.post2('myCollection', data)
  }, taskNotice(data) {
    return request.get('taskNotice', data)
  }

}