<template>
  <div class="importantFormBox">
    <div class="search">
      <van-field v-model="queryParams.condition" placeholder="输入搜索内容" type="text" input-align="left">
        <template slot="left-icon">
          <img style="width: 15px; height: 15px" :src="searchIcon" alt="" class="searchInput" />
        </template>
        <template slot="right-icon">
          <div @click="clickSearch">
            搜索
          </div>
        </template>
      </van-field>
    </div>
    <div class="conBox" v-for="(item, index) in dataList" :key="index">
      <div class="title">
        <span class="word">{{ item.monitorTerm }} </span>
        <div class="Icon">
          <img :src="jiankongIcon" @click="refresh" alt="" />
          <img @click="shoucang(item, index)" :src="startLiang" alt="" />
        </div>
      </div>
      <div class="video">
        <!-- 监控视频         -->
        <LivePlayer ref="LivePlayer" v-if="$isIOS" :videoURL="item.hls"></LivePlayer>
        <flvPlayer ref="focusOnervise" v-else :videoURL="item.wsFlv"></flvPlayer>
      </div>
    </div>
    <nodata v-if="dataList.length==0"></nodata>
  </div>
</template>
<script>
import api from '@/api/home/index';
import flvPlayer from '@/components/monitor/flvPlayer.vue';
import LivePlayer from '@/components/monitor/LivePlayer.vue';
import request from '@/utils/request'
import nodata from '@/components/no-data'
export default {
  components: {
    flvPlayer,
    LivePlayer,
    nodata
  },
  data () {
    return {
      queryParams: {
        condition: '',
        facilityId: null,
        scenicSpotId: null,
        type: 2,
      },
      dataList: [],
      form: {
        search: ''
      },
      list: [],
      searchIcon: require('@/assets/img/important/search.png'),
      jiankongIcon: require('@/assets/img/important/jiankong.png'),
      startIcon: require('@/assets/img/important/start.png'),
      startLiang: require('@/assets/img/important/startliang.png')
    };
  },
  mounted () {
    // this.getList();
    this.allMonitorInfo()
  },
  methods: {
    //重点关注
    allMonitorInfo () {
      request
        .post('/onlineMonitor/getOnlineMonitor', this.queryParams)
        .then((res) => {
          if (res.code == '200' && res.success) {
            const { equipmentVOS } = res.data
            const isFocusdata = equipmentVOS.filter(item => {
              return item.isFocus == 1
            })
            this.dataList = isFocusdata
          }
        })

    },
    shoucang (item, index) {
      //将设备加入收藏夹 1收藏 0取消收藏
      let id = item.id
      request.post('/onlineMonitor/editFocus', { id: id }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$toast({
            message: '取消收藏',
            icon: this.startIcon
          });
          this.allMonitorInfo()
        }
      })
    },
    // 刷新
    refresh () {
      this.allMonitorInfo()
    },

    // getList () {
    //   api.importantList({ type: 0 }).then((res) => {
    //     console.log(res);
    //     const aa = { isFocus: 1 };
    //     this.list = res.data;
    //     console.log(this.list);
    //   });
    // },
    clickSearch () {
      this.allMonitorInfo()
    }
  }
};
</script>
<style lang="less" scoped>
@import url("~@/assets/less/conment.less");
.importantFormBox {
  margin: 10px 10px;
  .search {
    margin-top: 10px;
    height: 40px;
    width: 100%;
    .liangbian();
    .van-field {
      border-radius: 18px;
      align-items: center;
    }
  }
  .conBox {
    height: 232px;
    background: #ffffff;
    border-radius: 11px;
    padding: 10px 10px;
    margin-top: 12px;
    .shangxiafeng();
    .title {
      height: 18px;
      .liangbian();
      .word {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #363a44;
      }
      .Icon {
        width: 47px;
        .liangbian();
        img {
          &:first-child {
            width: 15px;
          }
          width: 18px;
          height: 18px;
        }
      }
    }
    .video {
      height: 177px;
      border-radius: 9px;
      video {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
