import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

Vue.filter('dayjs', function (dataStr, pattern = 'YYYY-MM-DD') {
  if (!dataStr) return '--'
  return moment(dataStr).format(pattern)
})

// 返回星期几
Vue.filter('dayjsweekday', function (dataStr, pattern = 'YYYY-MM-DD') {
  if (!dataStr) return '--'
  return moment().format('dddd')
})
// 是否逾期
Vue.filter('datetimejsyuqi', function (dataStr, pattern = 'MM-DD HH:mm') {
  if (!dataStr) return '--'
  const timedatainterval = moment().unix() - moment(dataStr).unix()
  const daydata = 60 * 60 * 24
  if (timedatainterval > 0) {
    const dayinterval = parseInt(timedatainterval / daydata)

    return dayinterval
  }
  return '0'
})
// 时间
Vue.filter('datetime', function (dataStr, pattern = 'HH:mm') {
  if (!dataStr) return '--'
  return moment(dataStr).format(pattern)
})

// YYYY-MM-DD HH:mm:ss
Vue.filter('datetimejs', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  if (!dataStr) return '--'
  return moment(dataStr).format(pattern)
})
// YYYY年MM月DD日HH时mm分
Vue.filter(
  'datehourjs',
  function (dataStr, pattern = 'YYYY年MM月DD日 HH时mm分ss秒') {
    if (!dataStr) return '--'
    return moment(dataStr).format(pattern)
  }
)

Vue.filter('monthOfStartDay', function (momentObj) {
  if (momentObj && momentObj.startOf) {
    return momentObj.startOf('month')
  }
  return moment().startOf('month')
})

Vue.filter('monthOfEndDay', function (momentObj) {
  if (momentObj && momentObj.endOf()) {
    return momentObj.endOf('month')
  }
  return moment().endOf('month')
})

Vue.filter('dateStrToMoment', function (dataStr) {
  return moment(dataStr)
})

Vue.filter('statisticFormat', function (val) {
  if (isNaN(val)) {
    return 0
  }
  if (val <= -999) {
    val = 'lot'
  } else if (val >= 999) {
    val = 'few'
  }
  return val
})
/**
 * 手机号加铭感
 */
Vue.filter('phoneImpressively', function (val) {
  if (!val) {
    return '-'
  }
  val = String(val)
  return (
    val.substr(0, Math.min(val.length, 3)) +
    '****' +
    val.substr(Math.min(val.length, 7))
  )
})
