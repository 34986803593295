<template>
  <div class="bigBox">
    <div class="listBox" v-for="(item, index) in list" :key="index">
      <div class="title">
        <span class="address">{{ item.address }}</span>
        <span class="time">{{ item.time }}</span>
      </div>
      <div class="describe">
        {{ item.describe }}
      </div>
      <div class="imgBox">
        <img :src="weizhi" alt="" />
        <img :src="weizhi" alt="" />
        <img :src="weizhi" alt="" />
        <img :src="weizhi" alt="" />
      </div>
      <div class="whether">
        <van-cell title="浙江省杭州市桐庐县桐庐瑶琳镇东琳村" :icon="weizhi">
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #right-icon>
            <span class="kejian">暂不公开</span
            ><van-switch v-model="item.checked" size="24" />
          </template>
        </van-cell>
      </div>
      <div class="nameless">
        <span class="liang">{{ item.liang }}</span>
        <span>{{ item.nameles }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      weizhi: require('@/assets/img/zlb/dingwei.png'),
      shifou: require('@/assets/img/zlb/shifou.png'),
      checked: false,
      list: [
        {
          address: '垂云通天河-凌云天桥',
          time: '2002年5月20日 12:22:22',
          describe:
            '细节描述，细节描述细节描述细节描述细节描述细节描述细节描述细节描述细节描述细节描述细细节描述 细节描述细节描述，节描述',
          imgList: [],
          nameles:
            '细节描述，细节描述细节描述细节描 细节描述细节描述细节描述述',
          liang: '垂云通天河：',
          checked: false
        },
        {
          address: '垂云通天河-凌云天桥',
          time: '2002年5月20日 12:22:22',
          describe:
            '细节描述，细节描述细节描述细节描述细节描述细节描述细节描述细节描述细节描述细节描述细细节描述 细节描述细节描述，节描述',
          imgList: [],
          nameles:
            '细节描述，细节描述细节描述细节描 细节描述细节描述细节描述述',
          liang: '垂云通天河：',
          checked: false
        },
        {
          address: '垂云通天河-凌云天桥',
          time: '2002年5月20日 12:22:22',
          describe:
            '细节描述，细节描述细节描述细节描述细节描述细节描述细节描述细节描述细节描述细节描述细细节描述 细节描述细节描述，节描述',
          imgList: [],
          nameles:
            '细节描述，细节描述细节描述细节描 细节描述细节描述细节描述述',
          liang: '垂云通天河：',
          checked: false
        }
      ]
    };
  },
  methods: {}
};
</script>
<style lang="less" scoped>
.bigBox {
  padding: 12px 12px;
  .listBox {
    &:first-child {
      margin-top: 0px;
    }
    margin-top: 12px;
    height: 345px;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
    border-radius: 6px;
    padding: 13px 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      height: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .address {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .time {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #868686;
      }
    }
    .describe {
      height: 70px;
      overflow: hidden;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #686b73;
    }
    .imgBox {
      height: 87px;
      display: flex;
      justify-content: space-between;
      img {
        width: 23%;
        background: #ffffff;
        border: 1px dashed #bfbfbf;
      }
    }
    .whether {
      span {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #363a44;
        line-height: 27px;
      }
      .van-cell {
        padding: 0px 0px;
        .van-cell__left-icon {
          margin-top: 6px;
        }
      }
      .kejian {
        color: #b3b5b9;
        margin-right: 5px;
      }
    }
    .nameless {
      height: 61px;
      background: #f6f6f6;
      border-radius: 6px;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #686b73;
      padding: 10px 10px 0px 10px;
      .liang {
        color: #1c91ff;
      }
    }
  }
}
</style>
