import request from '@/api/index';

export default {
  warnList(data) {
    return request.get('warnList', data)
  },
  warningHandleDetail(data) {
    return request.get('warningHandleDetail', data)
  },
  //已审核详情
  auditWarningHandleDetail(data) {
    return request.get('auditWarningHandleDetail', data)
  },
  //审核提交
  auditWarningHandle(data) {
    return request.post2('auditWarningHandle', data)
  }
}