<template>
  <div id="app">
    <div class="content">
      <router-view />
    </div>
  </div>
</template>
<script>
// import VConsole from 'vconsole';
// let vconsole = new VConsole();
export default {
  components: {
    // Nav
  },
  data() {
    return {
      property: 'value'
    };
  },
  created() {


  }
};
</script>
<style lang="less">
#app {
  width: 375px;
  height: 100vh;
  margin: 0 auto;
}

.van-field__label,
.van-cell__title {
  color: #B3B5B9;
}

.van-cell__value {
  color: #363A44;
}


body {
  margin: 0;
  padding: 0;
}

.content {
  // height: calc(100% - 50px);
  height: 100%;
  overflow: scroll;
  background: #f5f5f5;
}
</style>
