<template>
  <div class="chakanBox">
    <div class="time">{{ topTime }}</div>
    <div class="buzou">
      <van-steps direction="vertical" :inactive-icon="buzouIcon" :active-icon="buzouIcon" active="0">
        <div v-for="(item, index) in allData" :key="index">
          <van-step v-if="item.title == '任务制定'">
            <span class="renwutime">
              {{ item.title }} {{ item.operateTime }}
            </span>
            <div class="buzouBox">
              <div class="list">
                <div class="title">任务编号</div>
                <div class="con">{{ item.jsonObject.taskCode }}</div>
                <div class="xian"></div>
              </div>
              <div class="list">
                <div class="title">任务标题</div>
                <div class="con">{{ item.jsonObject.title }}</div>
                <div class="xian"></div>
              </div>
              <div class="list">
                <div class="title">接收单位</div>
                <div class="con">{{ item.jsonObject.companyName }}</div>
                <div class="xian"></div>
              </div>
              <div class="list">
                <div class="title">查阅单位</div>
                <div class="con">{{ item.jsonObject.examineCompanyNames }}</div>
                <div class="xian"></div>
              </div>
            </div>
          </van-step>
          <van-step v-else-if="item.title == '接收意见'">
            <span class="renwutime">
              {{ item.title }} {{ item.operateTime }}
            </span>
            <div class="buzouBox">
              <div class="list">
                <div class="title">运营单位</div>
                <div class="con">{{ item.jsonObject.companyName }}</div>
                <div class="xian"></div>
              </div>
              <div class="list">
                <div class="title">处理人员</div>
                <div class="con">{{ item.jsonObject.personPerson }}</div>
                <div class="xian"></div>
              </div>
              <div class="list">
                <div class="title">联系方式</div>
                <div class="con">{{ item.jsonObject.mobile }}</div>
                <div class="xian"></div>
              </div>
            </div>
          </van-step>
          <van-step v-else-if="item.title == '意见反馈'">
            <span class="renwutime">
              {{ item.title }} {{ item.operateTime }}
            </span>
            <div class="buzouBox">
              <div class="list">
                <div class="title">反馈内容</div>
                <div class="con">
                  {{ item.jsonObject.feedbackComment }}
                </div>
                <div class="xian"></div>
              </div>
              <div class="list">
                <div class="tuBox">
                  <div class="title">反馈图片：</div>
                  <div class="imgBox" style="margin-top: 22px">
                    <img v-for="(item, index) in getImageData(item.jsonObject.imageUrl)" :key="index" :src="item" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </van-step>
          <!-- 部门查阅 -->
          <van-step v-else-if="item.title == '部门查阅'">
            <span class="renwutime">{{ item.title }} {{ item.operateTime }}</span>
            <div class="buzouBox">
              <div class="shenhe" v-for="(item, index) in item.operateLogVOS" :key="index">
                <div class="title" style="display: flex; justify-content: space-between">
                  <div>
                    <span class="danwei"> {{ item.companyName }}：</span>
                    <span class="state">
                      {{
                          item.examineState == 3
                            ? '已阅'
                            : item.examineState == 4
                              ? '退回'
                              : ''
                      }}</span>
                  </div>
                </div>
                <div v-if="item.examineState == 4">
                  <div class="xian"></div>

                  <div class="cause">退回意见: {{ item.reason }}</div>
                </div>
              </div>
            </div>
          </van-step>
          <van-step v-else-if="item.title == '查阅结果'">
            <span class="renwutime">{{ item.title }}{{ item.operateTime }}</span>
            <div class="buzouBox">
              <div class="shenhe">
                <div class="title">
                  查阅结果：
                  {{
                          item.jsonObject.examineState == 3
                            ? '已阅'
                            : item.jsonObject.examineState == 4
                              ? '退回'
                              : ''
                      }}
                </div>
                <!-- <div v-if="item.jsonObject.examineState == 4">
                  <div class="xian"></div>

                  <div class="cause">
                    退回意见: {{ item.jsonObject.reason }}
                  </div>
                </div> -->
              </div>
            </div>
          </van-step>
          <van-step v-else-if="item.title == '任务完成'">
            <span class="renwutime">{{ item.title }} {{ item.operateTime }}</span>
            <div class="buzouBox">
              <!-- <div class="list">
                <div class="title">查阅结果</div>
                <div class="con">
                  {{ item.jsonObject.examineState == 4 ? '退回' : '' }}
                </div>
                <div class="xian"></div>
              </div> -->
              <!-- <div class="list">
                <div class="title">退回单位</div>
                <div class="con">
                  {{ item.jsonObject.companyName }}
                </div>
                <div class="xian"></div>
              </div>
              <div class="list">
                <div class="title">退回意见</div>
                <div class="con">{{ item.jsonObject.reason }}</div>
                <div class="xian"></div>
              </div> -->
            </div>
          </van-step>
        </div>
      </van-steps>
    </div>
  </div>
</template>
<script>
import api from '@/api/task/index';
import { unique } from '@/utils/aa';
export default {
  data () {
    return {
      buzouIcon: require('@/assets/img/task/buzouyuan.png'),
      pass: require('@/assets/img/patrol/pass.png'),
      nopass: require('@/assets/img/patrol/nopass.png'),
      form: {
        taskId: 0
      },
      allData: {},
      titleArr: [],
      fabuData: {},
      fabudata: {},
      kaishiData: {},
      kaishidata: {},
      renwuData: {},
      renwudata: {},
      shenheData: [],
      departmentalInspection: {},
      departmentalInspectionArr: [],
      lookAtTheResults: [],
      feedbackPicture: [],
      receivingOpinion: {},
      feedback: {},
      lastData: {},
      topTime: ''
    };
  },
  created () {
    this.form.taskId = this.$route.query.id;
  },
  mounted () {
    this.getList();
  },
  methods: {
    //审核详情
    getList () {
      api.auditTaskDetail(this.form).then((res) => {
        if (res.code == '200') {
          console.log(res.data);
          this.topTime = res.data.businessDate;
          this.allData = res.data.operateLogList.map((i) => {
            i.operateLogVOS = [];
            return i;
          });
          let aa = JSON.parse(JSON.stringify(unique(res.data.operateLogList)));
          aa.forEach((item) => {
            if (item.title == '部门查阅') {
              item.operateLogVOS.unshift(item.jsonObject);
            }
          });
          console.log(aa);

          this.allData = aa;
          console.log(this.allData, '11111111111');
        }
      });
    },
    getImageData (data) {
      if (Array.isArray(data)) {
        return data;
      }
      if (JSON.parse(data)) {
        return JSON.parse(data)
      } else {
        return []
      }
    }
  },
  watch: {}
};
</script>
<style lang="less" scoped>
.hengxian {
  width: 90%;
  height: 1px;
  background: #e8e9ec;
  align-self: flex-end;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.chakanBox {
  margin: 10px 10px;
  background: #fff;
  padding: 10px 0px 72px 0px;
  border-radius: 10px;

  .time {
    height: 33px;
    width: 220px;
    margin-left: 10px;
    background: #1c91ff;
    border-radius: 4px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 33px;
  }

  .renwutime {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #1c91ff;
  }

  .buzou {
    margin-top: 12px;
  }

  .buzouBox {
    background: #f6f7f8;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 18px;
    font-family: PingFang SC;
    font-weight: 400;

    .list {
      position: relative;
      display: flex;
      padding: 17px;
      align-items: center;

      .title {
        width: 80px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
        white-space: nowrap;
        // width: 70px;
      }

      .con {
        color: #363a44;
        margin-left: 10px;
      }

      .xian {
        .hengxian();
      }

      .tuBox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .imgBox {
          img {
            width: 95px;
            height: 82px;
            margin-left: 10px;
            &:first-child {
              margin-left: 0px;
            }
          }
        }
      }
    }

    .shenhe {
      display: flex;
      padding: 18px 20px 18px 17px;
      flex-direction: column;
      position: relative;

      .title {
        .danwei {
          color: #686b73;
        }

        .state {
          color: #363a44;
        }
      }

      .xian {
        height: 1px;
        width: 90%;
        background: #aaaaaa;
        opacity: 0.15;
        position: absolute;
        bottom: 0;
      }

      .cause {
        margin-top: 18px;
      }

      img {
        width: 23px;
        height: 23px;
      }
    }
  }
}

/deep/ .van-cell {
  font-size: 17px;
  color: #686b73 !important;
  padding: 10px 10px;
}

/deep/ .van-step--vertical .van-step__line {
  width: 0 !important;
  border: 1px dashed #aaaaaa !important;
}

/deep/.van-step--vertical:not(:last-child)::after {
  border-bottom: 0px;
}
</style>
