<template>
  <div class="xiangqingBox">
    <div class="jiben" style=" margin-top: 10px;padding-top: 10px;">
      <div class="infomation">
        <div style="margin:0" class="kuai"></div>
        <span style="font-size:16px">基本信息</span>
        <div class="xian"></div>
      </div>
      <div class="list">
        <div class="con">
          <van-field label="任务编号" v-model="Data.taskCode" readonly />
        </div>
        <div class="xian"></div>
      </div>
      <div class="list">
        <!-- <div class="title">任务标题</div> -->
        <div class="con">
          <van-field label="任务标题" v-model="Data.title" readonly></van-field>
        </div>
        <div class="xian"></div>
      </div>
      <div class="list">
        <!-- <div class="title">任务标题</div> -->
        <div class="con">
          <van-field type="textarea" label="接收单位" v-model="Data.companyName" rows="1" autosize readonly></van-field>
        </div>
        <div class="xian"></div>
      </div>
      <div class="list">
        <!-- <div class="title">任务标题</div> -->
        <div class="con">
          <van-field type="textarea" v-model="Data.auditCompanyName" rows="1" autosize readonly label="查阅单位"></van-field>
        </div>
        <div class="xian"></div>
      </div>
    </div>
    <div class="jiben" style="margin-top: 10px;padding-top: 10px;">
      <div class="infomation">
        <div style="margin:0" class="kuai"></div>
        <span style="font-size:16px">任务内容</span>
        <div class="xian"></div>
      </div>
      <div class="list" style="margin: 10px 0;">
        <van-field rows="1" label="任务详情" type="textarea" autosize readonly v-model="Data.comment" style="padding-left: 0px;font-size: 15px;">
        </van-field>
        <div class="xian"></div>
      </div>
      <div class="list">
        <div class="con">
          <van-field label="发布时间" readonly v-model="Data.createTime"></van-field>
        </div>

        <div class="xian"></div>
      </div>
    </div>
    <div class="jiben" style=" margin-top: 10px;padding-top: 10px;">
      <div class="infomation">
        <div style="margin:0" class="kuai"></div>
        <span style="font-size:16px">任务截止时间</span>
        <div class="xian"></div>
      </div>
      <div class="list">
        <div class="con">
          <van-field rows="1" label="截至时间" type="textarea" autosize readonly v-model="Data.taskTerm"></van-field>
        </div>
        <div class="xian"></div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api/task/index';
export default {
  data () {
    return {
      form: { taskId: null },
      Data: {}
    };
  },
  created () {
    this.form.taskId = this.$route.query.id;
  },
  mounted () {
    this.getList();
  },
  methods: {
    getList () {
      api.taskDetail(this.form).then((res) => {
        console.log(res);
        if (res.code == '200') {
          this.Data = res.data;
          console.log(this.Data);
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import url("~@/assets/less/conment.less");
@import url("~@/assets/less/vantChange.less");
.xiangqingBox {
  margin: 10px 10px 10px 10px;
}
.jiben {
  padding: 10px 0;
  &:nth-child(2) {
    .list {
      .shangxia();
    }
  }
  .jiben();
  .list {
    // height: 41px;
    align-items: flex-end;
    .van-cell {
      font-size: 18px;
      color: #686b73;
      background: transparent;
      position: static;
    }
    .con {
      width: 100%;
      .van-cell {
        color: #363a44 !important;
        font-size: 15px;
        padding: 10px 0px;
      }
      .van-cell__value {
        color: #b3b5b9;
      }
      .popupBox {
        padding: 48px 30px 38px 30px;
        .title {
          align-self: center;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #363a44;
        }
        .shangxia();
        .xuanze {
          .van-checkbox {
            justify-content: space-between;
            &:first-child {
              margin: 0 0 0 0;
            }
            margin-top: 27px;
          }
        }
        .buttomBox {
          width: 100%;
          height: 49px;
          .liangbian();
          align-items: center;
          margin-top: 94.5px;
          font-family: PingFang SC;
          font-weight: 400;
          .close {
            height: 49px;
            width: 137.5px;
            border-radius: 24.5px;
            border: 1px solid #0085ff;
            color: #0085ff;
            font-size: 18px;
            text-align: center;
            line-height: 49px;
          }
          .sure {
            width: 137.5px;
            height: 49px;
            border-radius: 24.5px;
            text-align: center;
            line-height: 49px;
            background: #1c91ff;
            color: #fff;
            font-size: 18px;
          }
        }
      }
    }
  }
  /deep/ .van-field__label {
    color: #b3b5b9;
    font-size: 15px;
    margin-right: 17px;
  }
}
</style>
