<template>
  <div class="palyer">
    <LivePlayer ref="palyer" :videoUrl="videoURL" :autoplay="true" :poster="img" live fluent stretch :hide-snapshot-button="true"/>
  </div>
</template>

<script>
import LivePlayer from '@liveqing/liveplayer'
export default {
  name: 'LivePlayerDemo',
  components: {
    LivePlayer
  },
  props: {
    videoURL: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    }
  },
  created () {

  },
  watch: {
    // videoURL () {
    //   if (this.videoURL)
    //   this.play();
    // }
  },
  computed: {

  },

  methods: {
    // 播放
    play () {
      this.$refs.palyer.play();
    },
    // 暂停
    pause () {
      this.$refs.palyer.pause();
    },

  }
}
</script>



<style scoped>
.palyer {
  width: 100%;
  height: 100%;
  margin: auto;
}
/deep/.video-wrapper-stretch{
  position: static !important;
}
</style>
