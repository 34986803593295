<template>
  <div class="patrolBox">

    <div class="option">
      <div @click="yishenClick()" :class="yishenShow ? 'yishenheClick' : 'yishenheNoClick'">
        <div class="icon"></div>
        <div class="word">
          <!-- <div class="dian" v-show="yishenNumber!=0">{{ yishenNumber }}</div> -->
          已巡查
        </div>
      </div>
      <div @click="xunClick()" :class="xunShow ? 'xunClick' : 'yishenheNoClick'">
        <div class="icon"></div>
        <div class="word">
          <div class="dian" v-show="xunNumber!=0">{{ xunNumber }}</div>
          待巡查
        </div>
      </div>
    </div>
    <Search v-model="form" @changeclickSearch="clickSearch" @changeonConfirm="onConfirm"></Search>
    <div class="list">
      <van-list v-model="loading" :finished="finished" :finished-text="text" @load="onLoad" :offset="0" :immediate-check="false">
        <div class="box" v-for="(item, index) in Data" :key="index" @click="xiangqing(item)">
          <div class="contentBox">
            <div class="word">
              <div class="title">{{ item.name }}</div>
              <div class="biaoti">巡查标题：{{ item.title }}</div>
              <div class="fankui">
                巡查反馈：{{
                  item.patrolResult == 1
                    ? "正常"
                    : item.patrolResult == 2
                    ? "异常"
                    : "--"
                }}
              </div>
              <div class="time">
                {{ form.examineState == 1 ? "发布时间：" : "上传时间："
                }}{{
                  form.examineState == 1 ? item.createTime : item.patrolTime
                }}
              </div>
            </div>
            <div v-if="xunShow" @click.stop="chakan(item)" class="chakan">
              <van-button type="info">查看</van-button>
            </div>
            <img v-else :src="item.imageUrl ? item.imageUrl : zhanweiicon" alt="" />
          </div>
        </div>
      </van-list>
    </div>
    <no-data v-if="Data.length == 0"></no-data>
    <div style="height:50px">
      <Nav></Nav>
    </div>
  </div>

</template>
<script>
import api from "@/api/Patrol/index";
import Nav from "@/components/nav/nav.vue";
import noData from '@/components/no-data'
import Search from '@/components/Search'
import moment from 'moment'
export default {
  components: {
    Nav,
    noData,
    Search
  },
  data () {
    return {
      text: '没有更多了',
      form: {
        pageNum: 1,
        pageSize: 10,
        keyWord: "",
        examineState: 2,
        startTime: "",
        endTime: "",

      },
      Data: [],
      zhanweiicon: require("../assets/img/login/zhanwei.png"),
      searchIcon: require("../assets/img/patrol/search.png"),
      yishenShow: true,

      yishendian: true,
      daiShow: false,
      daishenNumber: 0,
      daishendian: true,
      xunShow: false,
      yishenNumber: 0,
      xunNumber: 0,
      xundian: true,
      state: "已审核",
      loading: false,
      finished: false,
    };
  },
  created () {
    this.yiShow = true;
    if (this.yishenNumber == 0 || this.yishenShow == true) {
      this.yishendian = false;
    } else if (this.daishenNumber == 0 || this.daiShow == true) {
      this.yishendian = false;
    }
    if (this.xunNumber === 0) {
      this.xundian = false
    }
    if (this.$route.query.type) {
      //待巡查
      this.text = "";
      this.form.examineState = 1;
      this.form.startTime = moment().format('YYYY-MM-DD');
      this.form.endTime = moment().format('YYYY-MM-DD');
      this.form.pageNum = 1;
      this.finished = false;
      this.yishenShow = false;
      this.daiShow = false;
      this.xunShow = true;
      this.xundian = false;
    } else {
      this.form.startTime = ""
      this.form.endTime = ""
    }
  },
  mounted () {
    this.getList();
    this.getUnread();
  },
  methods: {
    onLoad () {
      this.form.pageNum++;
      this.loading = false;
      api.xunchaList(this.form).then((res) => {
        if (res.rows.length !== 0) {
          for (var i in res.rows) {
            this.Data.push(res.rows[i]);
          }
        } else {
          this.finished = true;
          return;
        }
      });
    },
    xiangxi (item) {
      this.$router.push({
        name: "patrolForm",
        query: {
          id: item,
        },
      });
    },

    clickSearch () {
      this.form.pageNum = 1
      this.getList()
    },
    onConfirm () {
      this.getList()
    },
    yishenClick () {
      this.finished = false
      this.text = "";
      this.$router.replace({
        path: "/patrol",
        query: {},
      });
      //已巡查
      this.form.examineState = 2;
      this.form.pageNum = 1
      this.form.keyWord = "",
        this.form.startTime = "",
        this.form.endTime = "",
        this.getList();
      this.yishenShow = true;
      this.yishendian = false;
      this.daiShow = false;
      this.xunShow = false;
    },
    //查看跳转详情
    chakan (item) {
      this.$router.push({
        name: "patrolForm",
        query: {
          id: item.id,
          state: item.examineState
        },
      });
    },
    //跳转详情或者基本信息页面
    xiangqing (item) {
      if (item.examineState == 3 || item.examineState == 5) {
        this.$router.push({
          name: "xunchashenhe",
          query: {
            id: item.id,
            state: item.examineState
          },
        });
      } else {
        this.$router.push({
          name: "patrolForm",
          query: {
            id: item.id,
            state: item.examineState
          },
        });
      }


    },
    daishenClick () {
      this.form.examineState = 2;
      this.yishenShow = false;
      this.daishendian = false;
      this.daiShow = true;
      this.xunShow = false;
      this.getList();
    },
    xunClick () {
      //待巡查
      this.finished = false;
      this.text = "";
      this.form.examineState = 1;
      this.form.pageNum = 1;
      this.yishenShow = false;
      this.daiShow = false;
      this.xunShow = true;
      this.xundian = false;
      this.$router.replace({
        path: "/patrol",
        query: {
          type: 1,
        },
      });
      this.form.startTime = moment().format('YYYY-MM-DD'),
        this.form.endTime = moment().format('YYYY-MM-DD'),
        this.getList();
    },
    //请求列表数据
    getList () {
      this.Data = [];
      api.xunchaList(this.form).then((res) => {
        this.Data = res.data.rows;
      });
    },
    getUnread () {
      api.patrolListNum({
        scenicSpotId: null
      }).then((res) => {
        if (res.code == "200") {
          console.log(res.data);
          this.yishenNumber = res.data.implementNum;
          this.xunNumber = res.data.unImplementNum;
        } else {
          this.$toast(res.message);
        }
      });
    },
  },

};
</script>
<style lang="less" scoped>
.patrolBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .search {
    margin-top: 10px;
    height: 36px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 10px;

    .van-field {
      border-radius: 18px;
      align-items: center;
      margin-right: 10px;
    }

    .van-button {
      background: transparent;
      border: none;
      color: #1c8ffb;
      width: 67px;
      height: 40px;
      border-radius: 18px;
      padding: 0 0 0 0;

      .van-button__content {
        .van-button--default {
          color: #1c8ffb !important;
        }
      }
    }
  }

  .option {
    height: 50px;
    border-radius: 25px;
    background: #eeeeee;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    margin: 10px 10px 0px 10px;

    .box {
      width: 143px;
      height: 42px;
      border-radius: 36px;
      background: #1c91ff;
      color: #fff;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 15px;
      box-shadow: 0px 4px 4px rgba(63, 141, 235, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .iconstyle {
      width: 16px;
      height: 16px;
      background-size: 100% 100%;
      margin-top: 5px;
    }

    .dianstyle {
      width: 14px;
      height: 14px;
      background: #e84b4b;
      border: 1px solid #dd4646;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      right: 40px;
      top: 2px;
      line-height: 14px;
      font-size: 12px;
      color: #fff;
    }

    .yishenheClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("~@/assets/img/patrol/yishenhe.png");
      }

      .word {
        margin-left: 10px;
      }
    }

    .yishenheNoClick {
      width: 143px;
      height: 36px;
      color: #333333;
      font-weight: 500;
      font-size: 15px;
      font-family: PingFang SC;
      line-height: 38px;

      .word {
        position: relative;
        text-align: center;

        .dian {
          .dianstyle();
        }
      }
    }

    .daishenheClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("~@/assets/img/patrol/daishenhe.png");
      }
    }

    .xunClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("~@/assets/img/patrol/daixuncha.png");
      }

      .word {
        margin-left: 10px;
      }
    }
  }

  .list {
    margin-top: 10px;
    height: 630px;
    overflow-y: auto;
    padding: 0 10px 10px 10px;

    // margin-top: 15px;
    .box {
      &:first-child {
        margin-top: 0px;
      }

      margin-top: 10px;
      height: 120px;
      border-radius: 10px;
      position: relative;
      background: #fff;
      display: flex;
      align-content: center;
      flex-direction: column;
      justify-content: center;

      .biaoshiStyle {
        position: absolute;
        left: 0;
        top: 0;
        width: 50px;
        height: 20px;
        color: #fff;
        border-radius: 10px 0px 10px 0px;
        font-size: 12px;
        text-align: center;
        line-height: 20px;
      }

      .lvbiaoshi {
        .biaoshiStyle();
        background: #50cb79;
        box-shadow: 0px 2px 1px 0px rgba(21, 146, 63, 0.2);
      }

      .huangbiaoshi {
        background: #ffac20;
        .biaoshiStyle();
        box-shadow: 0px 2px 1px 0px rgba(216, 137, 3, 0.2);
      }

      .hongbiaoshi {
        background: #ff6661;
        .biaoshiStyle();
        box-shadow: 0px 2px 1px 0px rgba(226, 57, 51, 0.2);
      }

      .contentBox {
        display: flex;
        justify-content: space-between;
        margin: 5px 15px 0px 15px;

        .word {
          font-family: PingFang SC;
          font-weight: 400;
          color: #686b73;
          font-size: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          box-sizing: border-box;

          .title {
            font-size: 15px;
            font-weight: 600;
            color: #363a44;
            display: flex;
            justify-content: space-between;
          }

          .biaoti {
            width: 215px;
            margin-top: 4px;
            white-space: nowrap; // 不换行
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        img {
          height: 78.5px;
          width: 106px;
          border-radius: 8px;
        }

        .chakan {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .van-button {
            height: 21px;
            box-shadow: 0px 2px 3px 0px rgba(28, 128, 222, 0.2);
            border-radius: 10px;
            font-size: 12px;
            background: #1c91ff;
            font-family: PingFang SC;
            font-weight: 400;
          }
        }
      }
    }
  }
}

/deep/.van-cell {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}
</style>
