// export const unique = (arr) => {
//   // 获取初始的一条
//   let newArr = [arr[0]];
//   for (let i = 1; i < arr.length; i++) {
//     if (arr[i].title !== newArr[newArr.length - 1].title) {
//       newArr.push(arr[i]);
//     } else {
//       newArr[newArr.length - 1].createTime = arr[i].createTime;
//       newArr[newArr.length - 1].jsonObject.push(...arr[i].jsonObject);
//     }
//   }
//   return newArr;
// };

// 小程序方法
export const unique = (arr) => {
  // 获取初始的一条
  // console.log(arr);
  let newArr = [arr[0]];
  for (let i = 1; i < arr.length; i++) {
    console.log(i);
    if (arr[i].title !== newArr[newArr.length - 1].title) {
      newArr.push(arr[i]);
      // console.log(newArr);
    } else {
      // if (!newArr[newArr.length - 1].operateLogVOS) { // 当前没有没有值，赋值空数组
      //   newArr[newArr.length - 1].operateLogVOS = []
      //   console.log(newArr);
      // }
      // // 给当前项operateLogVOS数组插入一条数据
      // console.log(arr[i].jsonObject);
      newArr[newArr.length - 1].operateLogVOS.push(arr[i].jsonObject);
    }
  }
  return newArr;
};
