<template>
  <div class="patrolBox" >
    <Search v-model="form" @changeclickSearch="clickSearch" @changeonConfirm="onConfirm"></Search>
    <div class="list">
      <div class="box" @click="xiangqing(item)" v-for="(item, index) in Data1" :key="index">
        <div class="biaoshiStyle" :style="{ backgroundColor: stateOptions(item.examineState).color }">
          {{ stateOptions(item.examineState).name }}
        </div>
        <div class="contentBox">
          <div style="display: flex;align-items: center;">
            <div class="imgBox">
              <img :src="user" alt="" class="user" />
            </div>
            <div class="word" style="margin-left: 20px">
              <div class="title">{{ item.scenicSpotName }}</div>
              <div class="biaoti">任务标题：{{ item.title }}</div>
              <div class="neirong">任务内容：{{ item.comment }}</div>
            </div>
          </div>
          <div class="time" style="
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #686b73;
            ">
            {{ dealTime(item.createTime) }}
          </div>
        </div>
      </div>
    </div>
     <no-data v-if="Data1.length ==0"></no-data>
  </div>
 
</template>
<script>
import api from "@/api/my/index";
import moment from "moment";
import noData from '@/components/no-data'
import Search from '@/components/Search'
// 1待处理、2处理中、3已处理、4已阅、5已退回
let options = [
  {
    id: 1,
    name: "待处理",
    color: "#ffac20",
  },
  {
    id: 2,
    name: "处理中",
    color: "#ffac20",
  },
  {
    id: 3,
    name: "已处理",
    color: "#ffac20",
  },
  {
    id: 4,
    name: "已阅",
    color: "#50cb79",
  },
  {
    id: 5,
    name: "已退回",
    color: "#ffac20",
  },
];
export default {
  components: {
    noData,
    Search
  },
  data () {
    return {
      form: {
        keyWord: "",
        examineState: 3,
        pageNum: 1,
        pageSize: 10,
        queryTime: "",
      },
      Data: [],
      Data1: [],
      searchIcon: require("@/assets/img/patrol/search.png"),
      yishenShow: true,
      yishenNumber: 1,
      yishendian: true,
      daiShow: false,
      daishenNumber: 2,
      daishendian: true,
      xunShow: false,
      xunNumber: 2,
      xundian: true,
      user: require("@/assets/img/task/user.png"),
      gaojin: require("@/assets/img/task/gaojin.png"),
      zhiding: require("@/assets/img/task/zhiding.png"),
      maxDate: new Date(new Date().valueOf() + 24 * 600 * 600 * 10000),
      minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      rili: false,
      riqi: require("@/assets/img/my/xiajiantou.png"),
    };
  },
  created () {
    this.getList();
  },
  methods: {
    xiangqing (i) {
      console.log(i.auditStatus);
      this.$router.push({
        name: "renwuyishenhe",
        query: {
          id: i.id,
          examineState: i.examineState,
        },
      });
    },
  
    clickSearch () {
      this.form.pageNum = 1
      this.getList()
    },
    onConfirm () {
      this.getList()
    },
    //关闭日历时间清空
    removeTime () {
      this.form.queryTime = "";
    },
    yishenClick (item) {
      console.log(item);
      this.yishenShow = true;
      this.yishendian = false;
      this.daiShow = false;
      this.xunShow = false;
    },
    daishenClick () {
      this.yishenShow = false;
      this.daishendian = false;
      this.daiShow = true;
      this.xunShow = false;
    },
    xunClick () {
      this.yishenShow = false;
      this.daiShow = false;
      this.xunShow = true;
      this.xundian = false;
    },
    getList () {
      api.releaseHistory(this.form).then((res) => {
        this.$toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
        console.log(res);
        this.Data1 = res.data;
      });
    },
    jialing (s) {
      return s < 10 ? "0" + s : s;
    },
    formatDate (date) {
      return `${date.getFullYear()}-${this.jialing(
        date.getMonth() + 1
      )}-${this.jialing(date.getDate())}`;
    },
   
    stateOptions (state) {
      return options.find((i) => i.id == state);
    },
    // 获取当前时间，如果是当天时间就取时间，不是当前日期取完整日期
    dealTime (time) {
      let now = moment().format("YYYY-MM-DD");
      let currentTime = moment(time).format("YYYY-MM-DD");
      if (now == currentTime) {
        return moment(time).format("HH:mm:ss");
      } else {
        return time;
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import url("~@/assets/less/conment.less");

.patrolBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 10px;
  padding-right: 10px;

  .search {
    margin-top: 10px;
    height: 40px;
    width: 100%;
    .liangbian();

    .van-field {
      border-radius: 18px;
      align-items: center;
      margin-right: 10px;
    }

    .van-button {
      background: #fff;
      border: none;
      color: #1c8ffb;
      width: 67px;
      height: 40px;
      border-radius: 18px;
      padding: 0 0 0 0;

      .van-button__content {
        .van-icon__image {
          width: 12px;
          height: 12px;
        }
      }

      .van-button__content {
        .van-button--default {
          color: #1c8ffb !important;
        }
      }
    }
  }

  .option {
    margin-top: 22px;
    height: 50px;
    border-radius: 25px;
    background: #eeeeee;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .box {
      width: 100px;
      height: 36px;
      border-radius: 36px;
      background: #1c91ff;
      color: #fff;
      font-size: 14px;
      box-shadow: 0px 4px 4px rgba(63, 141, 235, 0.3);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }

    .iconstyle {
      width: 16px;
      height: 16px;
      background-size: 100% 100%;
      margin-top: 3px;
    }

    .dianstyle {
      width: 14px;
      height: 14px;
      background: #e84b4b;
      border: 1px solid #dd4646;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      right: 18px;
      top: 2px;
      line-height: 14px;
      font-size: 12px;
      color: #fff;
    }

    .yishenheClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("~@/assets/img/patrol/yishenhe.png");
      }
    }

    .yishenheNoClick {
      width: 100px;
      height: 36px;
      color: #333333;
      font-weight: 500;
      font-size: 15px;
      font-family: PingFang SC;
      line-height: 38px;

      .word {
        position: relative;
        text-align: center;

        .dian {
          .dianstyle();
        }
      }
    }

    .daishenheClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("~@/assets/img/patrol/daishenhe.png");
      }
    }

    .xunClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("~@/assets/img/patrol/daixuncha.png");
      }
    }
  }

  .list {
    margin: 15px 0 20px 0;

    .box {
      margin-top: 10px;
      height: 127px;
      border-radius: 10px;
      position: relative;
      background: #fff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .biaoshiStyle {
        position: absolute;
        left: 0;
        top: 0;
        width: 50px;
        height: 20px;
        color: #fff;
        border-radius: 10px 0px 10px 0px;
        font-size: 12px;
        text-align: center;
        line-height: 20px;
      }

      .lvbiaoshi {
        .biaoshiStyle();
        background: #50cb79;
        box-shadow: 0px 2px 1px 0px rgba(21, 146, 63, 0.2);
      }

      .huangbiaoshi {
        .biaoshiStyle();
        background: #ffac20;
        box-shadow: 0px 2px 1px 0px rgba(216, 137, 3, 0.2);
      }

      .hongbiaoshi {
        background: #ff6661;
        .biaoshiStyle();
        box-shadow: 0px 2px 1px 0px rgba(226, 57, 51, 0.2);
      }

      .contentBox {
        margin: 5px 18px 0px 18px;
        position: relative;
        .imgBox {
          height: 50px;
          min-width: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 0px 1px 0px rgb(108, 107, 107);
          align-self: center;

          .gaojin {
            width: 100%;
            height: 100%;
          }

          .user {
            width: 20px;
            height: 18px;
          }
        }

        .word {
          font-family: PingFang SC;
          color: #686b73;
          font-size: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            font-size: 15px;
            font-weight: 600;
            color: #363a44;
          }

          .biaoti {
            margin-top: 10px;
          }
        }

        > img {
          height: 79px;
          width: 106px;
          border-radius: 5px;
          align-self: end;
        }
        .time {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
}
</style>
