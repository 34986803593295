<template>
  <div class="chakanBox">
    <div class="time">{{ allData.businessDate }}</div>
    <div class="buzou">
      <van-steps direction="vertical" :inactive-icon="buzouIcon" :active-icon="buzouIcon" active="0">
        <!-- 消息发送 暂时 -->
        <!-- <van-step>
          <p class="renwutime">
            {{ fasongData.title }} {{ fasongData.operateTime }}
          </p>
          <div class="buzouBox" style="height: 256px">
            <div class="list" style="height: 96px">
              <div class="title" style="align-self: flex-start">预警原因</div>
              <div class="con" style="align-self: flex-start">
                {{ fasongData.jsonObject.warningContent }}
              </div>
              <div class="xian"></div>
            </div>
            <div class="list">
              <div class="title">预警设备</div>
              <div class="con">{{ fasongData.jsonObject.monitorTerm }}</div>
              <div class="xian"></div>
            </div>
            <div class="list">
              <div class="title">查阅单位</div>
              <div class="con van-ellipsis">
                {{ fasongData.jsonObject.auditCompanyName }}
              </div>
              <div class="xian"></div>
            </div>
          </div>
        </van-step> -->
        <van-step>
          <span class="renwutime">
            {{ kaishiData.title }} {{ kaishiData.operateTime }}
          </span>
          <div class="buzouBox">
            <div class="list">
              <div class="title">预警设施</div>
              <div class="con">{{ kaishidata.facilityName }}</div>
              <div class="xian"></div>
            </div>
            <div class="list">
              <div class="title">预警项</div>
              <div class="con">{{ kaishidata.monitorTerm }}</div>
              <div class="xian"></div>
            </div>
            <div class="list">
              <div class="title">预警内容</div>
              <div class="con">{{ kaishidata.warningContent }}</div>
            </div>
          </div>
        </van-step>
        <van-step>
          <span class="renwutime">
            {{ chuliData.title }} {{ chuliData.operateTime }}
          </span>
          <div class="buzouBox">
            <div class="list">
              <div class="title">运营单位</div>
              <div class="con">{{ chulidata.companyName }}</div>
              <div class="xian"></div>
            </div>
            <div class="list">
              <div class="title">处理人员</div>
              <div class="con">
                {{ chulidata.realName }}
              </div>
              <div class="xian"></div>
            </div>
            <div class="list">
              <div class="title">联系方式</div>
              <div class="con">
                {{ chulidata.mobile }}
              </div>
              <div class="xian"></div>
            </div>
          </div>
        </van-step>
        <van-step>
          <span class="renwutime">{{ shenheData.title }} {{ dealTime(shenheData.createTime) }}</span>
          <div class="buzouBox">
            <div class="list">
              <div class="title">反馈内容</div>
              <div class="con">
                {{ shenhedata.feedback }}
              </div>
              <div class="xian"></div>
            </div>
            <div class="list" style="align-self: flex-start">
              <div class="tuBox">
                <div class="title">反馈图片</div>
                <div class="imgBox" style="margin-top: 22px">
                  <img v-for="(item, index) in shenheData.imageList" :key="index" :src="item" alt="" />
                </div>
              </div>
            </div>
          </div>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>
<script>
import api from '@/api/waring/index';
import moment from 'moment'
export default {
  data () {
    return {
      buzouIcon: require('@/assets/img/task/buzouyuan.png'),
      pass: require('@/assets/img/patrol/pass.png'),
      nopass: require('@/assets/img/patrol/nopass.png'),
      form: {
        warningHandleId: this.$route.query.id
      },
      allData: {},
      fasongData: {},
      fasongdata: {},
      kaishiData: {},
      kaishidata: {},
      chuliData: {},
      chulidata: {},
      shenheData: {},
      shenhedata: {},
    };
  },
  created () {
    this.form.warningHandleId = this.$route.query.id;
    this.getList();
  },
  mounted () {
  },
  methods: {
    //审核详情
    getList () {
      console.log(this.form);
      api.auditWarningHandleDetail(this.form).then((res) => {
        if (res.code == '200') {
          console.log(res.data);
          this.allData = res.data;
          // this.fasongData = res.data[0];
          // this.fasongdata = this.fasongData.jsonObject;
          this.kaishiData = res.data.operateLogList[0];
          this.kaishidata = this.kaishiData.jsonObject;
          this.chuliData = res.data.operateLogList[1];
          this.chulidata = this.chuliData.jsonObject;
          this.shenheData = res.data.operateLogList[2];
          this.shenhedata = this.shenheData.jsonObject;
          //   let a = JSON.parse(JSON.stringify(this.allData.operateLogList));
          //   let a1 = a;
          //   let arr = a1.splice(2);
          //   let Arr = [];
          //   Arr.push(arr);
          //   Arr.forEach((i) => {
          //     i.forEach((j) => {
          //       this.shenheData.push(j);
          //     });
          //   });
        }
        // console.log(this.shenheData, "shenheData");
      });
    },
    // 获取当前时间，如果是当天时间就取时间，不是当前日期取完整日期
    dealTime (time) {
      let now = moment().format('YYYY-MM-DD');
      let currentTime = moment(time).format('YYYY-MM-DD');
      if (now == currentTime) {
        return moment(time).format('HH:mm:ss')
      } else {
        return time;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.hengxian {
  width: 90%;
  height: 1px;
  background: #e8e9ec;
  align-self: flex-end;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.chakanBox {
  margin: 10px 10px;
  background: #fff;
  padding: 10px 0px 72px 0px;
  border-radius: 10px;

  .time {
    height: 33px;
    width: 220px;
    margin-left: 10px;
    background: #1c91ff;
    border-radius: 4px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 33px;
  }

  .renwutime {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #1c91ff;
  }

  .buzouBox {
    background: #f6f7f8;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 18px;
    font-family: PingFang SC;
    font-weight: 400;

    .list {
      position: relative;
      display: flex;
      padding: 17px;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }

      .con {
        color: #333333;
        font-size: 14px;
        font-weight: 500;
        width: 71%;
      }

      .xian {
        .hengxian();
      }

      .tuBox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .imgBox {
          img {
            width: 80px;
            height: 82px;
            margin: 0 5px;

            &:last-child {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .shenhe {
      padding: 18px;

      .title {
        .danwei {
          color: #686b73;
        }

        .state {
          color: #363a44;
        }
      }

      img {
        width: 23px;
        height: 23px;
      }
    }
  }
}

.van-step--vertical:not(:last-child)::after {
  border-bottom-width: 0px;
}

/deep/ .van-step--vertical .van-step__line {
  width: 0 !important;
  border: 1px dashed #aaaaaa !important;
}
</style>
