<template>
  <div class="FormBox">
    <!-- <div v-if="xiangqingShow" class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>基本信息</span>
        <div class="xian"></div>
      </div>
      <div class="list" v-for="(item, index) in list" :key="index">
        <div class="title">{{ item.title }}</div>
        <div class="con">{{ item.con }}</div>
        <div class="xian"></div>
      </div>
    </div>
    <div v-if="xiangqingShow" class="project">
      <div class="infomation">
        <div class="kuai"></div>
        <span>巡查项目</span>
        <div class="xian"></div>
      </div>
      <div class="xiangqing">
        <div class="name">项目详情</div>
        <div class="con">
          <div class="shixiang" v-for="(item, index) in shixiang" :key="index">
            {{ item }}
          </div>
        </div>
        <div class="xian"></div>
      </div>
      <div class="time">
        <div class="title">发布时间</div>
        <div class="timer">{{ cretime }}</div>
        <div class="xian"></div>
      </div>
    </div> -->
    <van-form v-if="!xiangqingShow" @submit="onSubmit">
      <div class="jiben" style="height: 390xpx">
        <div class="infomation">
          <div class="kuai"></div>
          <span>预警详情</span>
          <div class="xian"></div>
        </div>
        <div class="list">
          <div class="title">处理单位</div>
          <div class="con">{{ Data.companyName }}</div>
          <div class="xian"></div>
        </div>
        <div class="list" style="height: 101px">
          <div class="title">预警原因</div>
          <div class="con" style="width: 67%">
            {{ Data.warningContent }}
          </div>
          <div class="xian"></div>
        </div>
        <div class="list">
          <div class="title">预警设备</div>
          <div class="con">{{ Data.equipmentCode }}</div>
          <div class="xian"></div>
        </div>
        <div class="list">
          <div class="title" style="width: 23%">查阅单位</div>
          <div class="con van-ellipsis">{{ Data.auditCompanyName }}</div>
        </div>
      </div>
      <div class="jiben" style="margin-top: 10px; height: 185px">
        <div class="infomation">
          <div class="kuai"></div>
          <span>执行人员</span>
          <div class="xian"></div>
        </div>
        <div class="list" v-for="(item, index) in zhixing" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="con">{{ item.con }}</div>
          <div class="xian"></div>
        </div>
      </div>
      <div class="jiben" style="margin-top: 10px; height: 379px">
        <div class="infomation">
          <div class="kuai"></div>
          <span>预警反馈</span>
          <div class="xian"></div>
        </div>
        <div class="list">
          <div class="title">预警状态</div>
          <div class="con">已正常</div>
          <div class="xian"></div>
        </div>
        <div class="list" style="height: 70px">
          <div class="title">反馈内容</div>
          <div class="con" style="width: 67%">{{ Data.feedback }}</div>
          <div class="xian"></div>
        </div>
        <div class="tu">
          <span>反馈图片</span>
          <div class="imgBox">
            <img
              v-for="(item, index) in Data.warningHandleImageList"
              :key="index"
              :src="item.imageUrl"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="jiben" style="margin-top: 10px; height: 438px">
        <div class="infomation">
          <div class="kuai"></div>
          <span>审核进度</span>
          <div class="xian"></div>
        </div>
        <van-steps
          direction="vertical"
          :active="Data.examineCompanyFlowList"
          active-color="#1C91FF"
          :active-icon="stepIcon"
        >
          <van-step
            v-for="(item, index) in Data.examineCompanyFlowList"
            :key="index"
          >
            <p style="color: #1c91ff">{{ item.modifiedTime }}</p>
            <div class="shenheqingkuang">
              <div>
                {{ item.auditCompanyName }}：<span>{{
                  item.examineState == 3 ? '通过' : '不通过'
                }}</span>
              </div>
              <img :src="item.examineState == 3 ? pass : nopass" alt="" />
            </div>
          </van-step>
        </van-steps>
      </div>
      <div class="jiben" style="margin-top: 10px; height: 318px">
        <div class="infomation">
          <div class="kuai"></div>
          <span>审核结果</span>
          <div class="xian"></div>
        </div>
        <van-radio-group v-model="form.auditResult" direction="horizontal">
          <van-radio :name="butongguo" @click="liuyan">
            不通过
            <template #icon="props">
              <img
                class="img-icon"
                :src="props.checked ? xuanzhongIcon : inactiveIcon"
              />
            </template>
          </van-radio>
          <van-radio :name="tongguo" @click="noliuyan">
            通过
            <template #icon="props">
              <img
                class="img-icon"
                :src="props.checked ? xuanzhongIcon : inactiveIcon"
              />
            </template>
          </van-radio>
        </van-radio-group>
        <van-field
          v-show="liuyanShow"
          v-model="form.reason"
          rows="1"
          label="意见"
          type="textarea"
          placeholder="请输入意见"
          maxlength="138"
        />
      </div>
    </van-form>
    <van-button
      v-if="!xiangqingShow"
      round
      type="info"
      block
      native-type="submit"
      @click="tijiao()"
      style="margin-top: 28px; margin-bottom: 28px; font-size: 20px"
      >提交</van-button
    >
  </div>
</template>
<script>
import api from '@/api/waring/index';
export default {
  data() {
    return {
      form: {
        auditResult: 0,
        reason: '',
        id: 0
      },
      tongguo: 3,
      butongguo: 4,
      shixiang: [],
      zhixing: [
        {
          title: '人员姓名',
          con: 'xxx'
        },
        {
          title: '联系电话',
          con: '123-1234-5678'
        }
      ],
      fankui: [
        {
          text: '1.滑道表面是否干燥、平整、坚实、光滑，有无霉变、杂物，滑道末端阻尼垫铺设是否正常。',
          state: 0
        },
        {
          text: '2.乘客滑行装备（含防护裤、手套、头盔等）有无破损、是否可正常使用。',
          state: 0
        }
      ],
      shenhejindu: [
        {
          step: '县文广旅体局: ',
          pass: 0,
          time: '10:10'
        },
        {
          step: '县城管局: ',
          pass: 1,
          time: '10:10'
        }
      ],
      xiangqingShow: false,
      activeIcon: require('@/assets/img/patrol/zhengchang.png'),
      inactiveIcon: require('@/assets/img/patrol/yichang.png'),
      xuanzhongIcon: require('@/assets/img/patrol/xuanzhong.png'),
      pass: require('@/assets/img/patrol/pass.png'),
      nopass: require('@/assets/img/patrol/nopass.png'),
      stepIcon: require('@/assets/img/patrol/stepIcon.png'),
      liuyanShow: true,
      cretime: '',
      id: {
        warningHandleId: 0
      },
      Data: {}
    };
  },
  created() {
    this.id.warningHandleId = this.$route.query.id;
    this.form.id = this.id.taskId;
    let state = this.$route.query.state;
    if (state == '已审核') {
      this.xiangqingShow = true;
      this.getlist();
    } else if (state == '待审核') {
      this.xiangqingShow = false;
    } else {
      this.xiangqingShow = false;
    }
  },
  mounted() {
    this.getlist();
  },
  methods: {
    //提交审核结果
    tijiao() {
      console.log('11111');
      api.auditWarningHandle(this.form).then((res) => {
        console.log('111');
        console.log(res);
        if (res.code == '200') {
          this.$toast('提交成功');
        } else {
          this.$toast(`提交失败!${res.message}!`);
        }
      });
    },
    //获取审核详情
    getlist() {
      api.auditWarningHandleDetail(this.id).then((res) => {
        this.Data = res.data;
        console.log(this.Data);
        this.zhixing[0].con = this.Data.realName;
        this.zhixing[1].con = this.Data.mobile;
      });
    },
    //获取巡查审核详情
    getshenhe() {
      api.patrolHandleSave().then((res) => {
        console.log(res.data);
      });
    },

    liuyan() {
      this.liuyanShow = true;
    },
    noliuyan() {
      this.liuyanShow = false;
      console.log('1111');
    },
    onSubmit(values) {
      console.log('1111', values);
    }
  }
};
</script>
<style lang="less" scoped>
@import url('~@/assets/less/conment.less');
.FormBox {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;

  .jiben {
    height: 297px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .infomation {
      padding-left: 10px;
      padding-right: 15px;
      display: flex;
      justify-content: flex-start;
      position: relative;
      height: 32px;
      .kuai {
        width: 5px;
        height: 19px;
        background: #1c91ff;
        margin-top: 3px;
      }
      .xian {
        width: 90%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      span {
        font-size: 19px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #363a44;
        margin-left: 10px;
        width: 90px;
      }
    }
    .list {
      // width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      position: relative;
      height: 32px;
      flex-direction: row;
      justify-content: space-between;
      // border-bottom: 1px solid #000;
      .title {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }
      .con {
        color: #363a44;
        font-size: 16px;
      }
      .xian {
        width: 90%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .fankui {
      height: 254px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      .fankuixiangqing {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #363a44;
        line-height: 23px;
      }
      .van-radio-group--horizontal {
        justify-content: center;
      }
      .img-icon {
        height: 16px;
      }
      .fankuiImg {
        display: flex;
        flex-direction: column;
        height: 110px;
        justify-content: space-between;
        .word {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #686b73;
        }
        img {
          width: 87px;
          height: 75px;
          border-radius: 5px;
          border: none;
        }
      }
      .xian {
        .hengxian();
      }
    }
    .result {
      height: 30px;
      display: flex;
      justify-content: space-between;
      .hezibj10px();
      position: relative;
      div {
        &:first-child {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #686b73;
        }
        &:nth-child(2) {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #363a44;
        }
      }
      .xian {
        .hengxian();
      }
    }
    .con {
      height: 93px;
      .liangbian();
      .hezibj10px();
      position: relative;
      span {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }
      div {
        width: 237px;
        height: 73px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #363a44;
      }
      .xian {
        .hengxian();
      }
    }
    .tu {
      .hezibj15px();
      .shangxia();
      height: 121px;
      span {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }
      img {
        .imgBox {
          .liangbian();
        }
        width: 95px;
        height: 82px;
        border-radius: 5px;
        &:last-child {
          margin-left: 10px;
        }
      }
    }
    .van-radio-group--horizontal {
      justify-content: center;
    }
    .img-icon {
      height: 18px;
    }
    .van-steps {
      .shenheqingkuang {
        height: 72px;
        background: #f6f7f8;
        border-radius: 8px;
        color: #686b73;
        font-size: 18px !important;
        .zhongjian();
        .liangbianfeng();
        span {
          color: #363a44;
        }
        img {
          width: 22px;
          height: 22px;
        }
      }
      .van-step--vertical:not(:last-child)::after {
        border-bottom-width: 0px;
      }
    }
  }
  .project {
    margin-top: 10px;
    height: 319px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    > .infomation {
      padding-left: 10px;
      padding-right: 15px;
      display: flex;
      justify-content: flex-start;
      position: relative;
      height: 32px;
      .kuai {
        width: 5px;
        height: 19px;
        background: #1c91ff;
        margin-top: 3px;
      }
      .xian {
        width: 90%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      span {
        font-size: 19px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #363a44;
        margin-left: 10px;
        width: 90px;
      }
    }
    .xiangqing {
      height: 165px;
      display: flex;
      justify-content: space-between;
      margin-left: 15px;
      margin-right: 15px;
      position: relative;

      .name {
        font-size: 16px;
        color: #686b73;
      }
      .con {
        width: 234px;
        font-size: 16px;
        color: #363a44;
        line-height: 23px;
        overflow: auto;
        margin-bottom: 5px;
        .shixiang {
          margin-top: 19px;
          &:first-child {
            margin-top: 0px;
          }
        }
      }
      .xian {
        width: 100%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .time {
      margin-left: 15px;
      margin-right: 15px;
      position: relative;
      height: 36px;
      display: flex;
      justify-content: space-between;
      color: #686b73;
      > .timer {
        align-self: flex-start;
        color: #363a44;
      }
      .xian {
        width: 100%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
/deep/.van-radio__label {
  font-size: 18px !important;
}
/deep/.van-step--vertical {
  font-size: 18px;
}
</style>
