<template>
  <div class="patrolBox">
    <div class="search">
      <van-field v-model="form.keyWord" placeholder="输入搜索内容" type="text" input-align="left">
        <template slot="left-icon">
          <img style="width: 15px; height: 15px" :src="searchIcon" alt="" class="searchInput" />
        </template>
        <template slot="right-icon">
          <div @click="clickSearch">
            搜索
          </div>
        </template>
      </van-field>
      <van-button size="normal" icon-position="right" :icon="zhiding" type="default" to="/zhiding/from">
        制定
      </van-button>
    </div>
    <div class="option">
      <div @click="yishenClick('已审核')" :class="yishenShow ? 'yishenheClick' : 'yishenheNoClick'">
        <div class="icon"></div>
        <div class="word">
          <!-- <div class="dian" v-show="yishenNumber!=0">{{ yishenNumber }}</div> -->
          已处理
        </div>
      </div>
      <div @click="xunClick('')" :class="xunShow ? 'xunClick' : 'yishenheNoClick'">
        <div class="icon"></div>
        <div class="word">
          <div class="dian" v-show="xunNumber!=0">{{ xunNumber }}</div>
          待处理
        </div>
      </div>
    </div>
    <div class="list">
      <van-list v-model="loading" :finished="finished" :finished-text="text" @load="onLoad" :offset="0" :immediate-check="false">
        <div class="box" @click="xiangqing(item)" v-for="(item, index) in Data" :key="index">
          <div class="contentBox">
            <div style="display: flex">
              <div v-if="xunShow" class="imgBox">
                <img :src="user" alt="" :class="item == 'gaojin' ? 'gaojin' : 'user'" />
              </div>

              <div class="word">
                <!-- <div class="title" v-if="xunShow">{{ item.companyName }}</div> -->
                <div class="title">{{ item.scenicSpotName }}</div>
                <div class="biaoti" v-if="xunShow">
                  {{ item.type == 'gaojin' ? '预警设备：' : '任务标题：'
                  }}{{ item.title }}
                </div>
                <div class="biaoti" v-if="xunShow == true ? false : true">
                  {{ item.type == 'gaojin' ? '预警项：' : '任务标题：'
                  }}{{ item.title }}
                </div>

                <!-- 这个待处理时出现 -->
                <div class="neirong van-ellipsis" v-if="xunShow">
                  {{ item.type == 'gaojin' ? '预警时间：' : '任务内容：'
                  }}{{ item.comment.slice(0, 10)  }}
                </div>
                <div class="fankui van-ellipsis">
                  任务反馈：
                  {{ item.feedbackComment }}
                </div>
                <div class="time" v-if="xunShow == true ? false : true">
                  {{ item.type == 'gaojin' ? '解除时间：' : '上传时间：'
                  }}{{ item.modifiedTime }}
                </div>
              </div>
            </div>
            <div v-if="xunShow" class="chakan">
              <div class="time">{{ dealTime(item.modifiedTime)}}</div>
              <van-button type="info" @click.stop="chakan(item)">查看</van-button>
            </div>
            <div @click.stop="toRead(item)" v-else :class="
              item.auditStatus == 3
                ? 'nocheck'
                : item.auditStatus == 0
                  ? 'check'
                  : 'nocheck'
            ">
              {{
                  item.auditStatus == 3
                    ? '已阅'
                    : item.auditStatus == 0
                      ? '待阅'
                      : '退回'
              }}
            </div>
            <!-- <img :src="item.imgUrl == '' ? '' : item.imgUrl" alt="" /> -->
          </div>
        </div>
      </van-list>
    </div>
    <no-data v-if="Data.length == 0"></no-data>
    <div style="height:50px">
      <Nav></Nav>
    </div>

  </div>

</template>
<script>
import Nav from '@/components/nav/nav.vue';
import moment from 'moment'
import api from '@/api/task/index';
import noData from '@/components/no-data'
export default {
  components: {
    Nav,
    noData
  },
  data () {
    return {
      form: {
        keyWord: '',
        examineState: 3,
        pageNum: 1,
        pageSize: 10,
        startTime: "",
        endTime: "",
      },
      Data: [
        {
          feedbackComment: ''
        }
      ],
      Data1: [],
      text: '没有更多了',
      searchIcon: require('../assets/img/patrol/search.png'),
      yishenShow: true,
      yishendian: true,
      daiShow: false,
      daishenNumber: 0,
      daishendian: true,
      xunShow: false,
      yishenNumber: 0,
      xunNumber: 0,
      xundian: true,
      user: require('../assets/img/task/user.png'),
      gaojin: require('../assets/img/task/gaojin.png'),
      zhiding: require('../assets/img/task/zhiding.png'),
      loading: false,
      finished: false
    };
  },
  created () {
    if (this.$route.query.type) {
      this.form.examineState = 1;
      this.form.pageNum = 1;
      this.text = "";
      this.finished = false;
      this.yishenShow = false;
      this.daiShow = false;
      this.xunShow = true;
      this.xundian = false;
      this.form.pageNum = 1;
    }
    this.getList();
    this.gettaskListNum();
    if (this.yishenNumber == 0 || this.yishenShow == true) {
      this.yishendian = false;
    } else if (this.daishenNumber == 0 || this.daiShow == true) {
      this.daishendian = false;
    }
  },
  mounted () {
  },
  methods: {
    onLoad () {
      this.form.pageNum++;
      api.taskList(this.form).then((res) => {
        if (res.code == '200') {
          if (res.rows.length !== 0) {
            for (var i in res.rows) {
              setTimeout(() => {
                res.rows[i].feedbackComment.substr(0, 9);
                res.rows[i].createTime.substr(11, 16);
              }, 100);

              this.Data.push(res.rows[i]);
            }
          } else {
            this.finished = true;
            return;
          }
        }
        this.loading = false;
      });
    },
    //获取未读数量
    gettaskListNum () {
      api.taskListNum().then((res) => {
        if (res.code == '200') {
          this.yishenNumber = res.data.passNum; //已审核未读
          this.daishenNumber = res.data.unAuditNum; //待审核未读
          this.xunNumber = res.data.unImplementNum; //待处理未读
          if (this.yishenNumber == 0) {
            this.yishendian = false;
            console.log(this.yishendian);
          }
          if (this.daishenNumber == 0) {
            this.daishendian = false;
            console.log(this.daishendian);
          }
          if (this.xunNumber == 0) {
            this.xundian = false;
            console.log(this.xundian);
          }
        } else {
          this.$toast(res.message);
        }
      });
    },
    //获取列表数据
    getList () {
      this.Data = [];
      //   this.$toast.loading({
      //     message: '加载中...',
      //     forbidClick: true
      //   });
      api.taskList(this.form).then((res) => {
        console.log(res);
        if (res.code == '200') {
          this.Data = res.data.rows;
          this.Data.map((i) => {
            return i.feedbackComment.substr(0, 10);
          });
          this.Data.forEach((i) => {
            return i.feedbackComment.substr(0, 10);
          });
        }
      });
    },

    toRead (i) {
      //待阅跳转
      if (i.auditStatus == 0) {
        this.$router.push({
          name: 'renwushenhe',
          query: {
            id: i.id,
            examineState: i.examineState
          }
        });
      } else {
        return;
      }
    },
    chakan (i) {
      this.$router.push({
        name: 'taskxiangqing',
        query: {
          id: i.id,
          examineState: i.examineState
        }
      });
    },
    xiangqing (i) {
      if (i.examineState == 3 || i.examineState == 4) {
        this.$router.push({
          name: 'renwuyishenhe',
          query: {
            id: i.id,
            examineState: i.examineState
          }
        });
      } else {
        this.$router.push({
          name: 'taskxiangqing',
          query: {
            id: i.id,
            examineState: i.examineState
          }
        });
      }


      //已阅跳转
      // if (i.auditStatus == 3 || i.auditStatus == 4) {
      //   this.$router.push({
      //     name: 'renwuyishenhe',
      //     params: {
      //       id: i.id,
      //       examineState: i.examineState
      //     }
      //   });
      // } else if (i.auditStatus == 0) {
      //   //未审核跳转页面
      //   this.$router.push({
      //     name: 'renwushenhe',
      //     params: {
      //       id: i.id,
      //       examineState: i.examineState
      //     }
      //   });
      // } else {
      //   return;
      // }
    },

    clickSearch () {
      this.form.pageNum = 1
      this.getList();
    },
    yishenClick () {
      this.form.examineState = 3;
      this.form.pageNum = 1;
      this.form.startTime = "",
        this.form.endTime = "",
        this.text = "";
      this.finished = false;
      this.getList();
      this.$router.replace({
        path: '/task',
      })
      this.yishenShow = true;
      this.yishendian = false;
      this.daiShow = false;
      this.xunShow = false;
    },
    daishenClick () {
      this.yishenShow = false;
      this.daishendian = false;
      this.daiShow = true;
      this.xunShow = false;
      this.form.examineState = 2;
      this.getList();
    },
    xunClick () {
      this.finished = false;
      this.form.examineState = 1;
      this.form.pageNum = 1;
      this.form.startTime = "",
        this.form.endTime = "",
        this.yishenShow = false;
      this.text = "";
      this.daiShow = false;
      this.xunShow = true;
      this.xundian = false;
      this.getList();
      this.$router.replace({
        path: '/task',
        query: {
          type: 1
        }
      })
    },
    // 获取当前时间，如果是当天时间就取时间，不是当前日期取完整日期
    dealTime (time) {
      let now = moment().format('YYYY-MM-DD');
      let currentTime = moment(time).format('YYYY-MM-DD');
      if (now == currentTime) {
        return moment(time).format('HH:mm:ss')
      } else {
        return moment(time).format('YYYY-MM-DD');
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import url("../assets/less/conment.less");

.patrolBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .search {
    margin-top: 10px;
    height: 36px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 10px;
    .liangbian();

    .van-field {
      border-radius: 18px;
      align-items: center;
      margin-right: 10px;
    }

    .van-button {
      background: #fff;
      border: none;
      color: #1c8ffb;
      width: 67px;
      height: 40px;
      border-radius: 18px;
      padding: 0 0 0 0;

      .van-button__content {
        .van-icon__image {
          width: 12px;
          height: 12px;
        }
      }

      .van-button__content {
        .van-button--default {
          color: #1c8ffb !important;
        }
      }
    }
  }

  .option {
    height: 50px;
    border-radius: 25px;
    background: #eeeeee;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    margin: 10px 10px 0px 10px;

    .box {
      width: 142px;
      height: 42px;
      border-radius: 36px;
      background: #1c91ff;
      color: #fff;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 15px;
      box-shadow: 0px 4px 4px rgba(63, 141, 235, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .iconstyle {
      width: 16px;
      height: 16px;
      background-size: 100% 100%;
      margin-top: 5px;
    }

    .dianstyle {
      width: 15px;
      height: 15px;
      background: #ff3a2f;
      border: 1px solid #ff3a2f;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      right: 37px;
      top: 0px;
      line-height: 15px;
      font-size: 9px;
      color: #fff;
    }

    .yishenheClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("~@/assets/img/patrol/yishenhe.png");
      }

      .word {
        margin-left: 10px;
      }
    }

    .yishenheNoClick {
      width: 143px;
      height: 36px;
      color: #333333;
      font-weight: 500;
      font-size: 15px;
      font-family: PingFang SC;
      line-height: 38px;

      .word {
        position: relative;
        text-align: center;

        .dian {
          .dianstyle();
        }
      }
    }

    .daishenheClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("~@/assets/img/patrol/daishenhe.png");
      }
    }

    .xunClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("~@/assets/img/patrol/daixuncha.png");
      }

      .word {
        margin-left: 10px;
      }
    }
  }

  .list {
    margin-top: 10px;
    height: 630px;
    overflow-y: auto;
    padding: 0 10px 60px 10px;

    .box {
      &:first-child {
        margin-top: 0px;
      }

      margin-top: 10px;
      height: 120px;
      border-radius: 10px;
      position: relative;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .biaoshiStyle {
        position: absolute;
        left: 0;
        top: 0;
        width: 50px;
        height: 20px;
        color: #fff;
        border-radius: 10px 0px 10px 0px;
        font-size: 12px;
        text-align: center;
        line-height: 20px;
      }

      .lvbiaoshi {
        .biaoshiStyle();
        background: #50cb79;
        box-shadow: 0px 2px 1px 0px rgba(21, 146, 63, 0.2);
      }

      .huangbiaoshi {
        background: #ffac20;
        .biaoshiStyle();
        box-shadow: 0px 2px 1px 0px rgba(216, 137, 3, 0.2);
      }

      .hongbiaoshi {
        background: #ff6661;
        .biaoshiStyle();
        box-shadow: 0px 2px 1px 0px rgba(226, 57, 51, 0.2);
      }

      .contentBox {
        display: flex;
        justify-content: space-between;
        margin: 5px 15px 0px 15px;

        .imgBox {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: center;
          margin-right: 11px;
          box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);

          .gaojin {
            width: 100%;
            // height: 100%;
          }

          .user {
            width: 20px;
            height: 18px;
          }
        }

        .check {
          width: 57px;
          height: 22px;
          background: #1c91ff;
          box-shadow: 0px 2px 3px 0px rgba(28, 128, 222, 0.2);
          border-radius: 11px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }

        .nocheck {
          width: 57px;
          height: 22px;
          background: #c5c7c8;
          box-shadow: 0px 2px 3px 0px rgba(180, 180, 180, 0.2);
          border-radius: 11px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }

        .word {
          font-family: PingFang SC;
          color: #686b73;
          font-weight: 400;
          font-size: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          word-break: break-all;

          .title {
            font-size: 15px;
            font-weight: 600;
            color: #363a44;
          }

          .biaoti {
            margin-top: 5px;
          }

          .neirong {
            width: 180px;
          }

          .fankui {
            width: 180px;
          }
        }

        > img {
          height: 79px;
          width: 106px;
          border-radius: 5px;
          align-self: end;
        }

        .chakan {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          > .time {
            color: #686b73;
            font-size: 11px;
            align-self: flex-end;
          }

          .van-button {
            width: 100%;
            height: 23px;
            width: 61px;
            box-shadow: 0px 2px 3px 0px rgba(28, 128, 222, 0.2);
            border-radius: 10px;
            font-size: 12px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
</style>
