<template>
  <div class="xiangxiBox" v-if="list1.length > 0">
    <div class="list" v-for="(item, index) in list1" :key="index" @click="yujingClick(item)">
      <img :src="item.imageUrl?item.imageUrl:zhanwei" alt="" />
      <div class="cont">
        <span>{{ item.name }}</span>
        <div class="shebei">设备总数{{ item.equipmentCount }}个</div>
        <div class="bottom">
          <div class="yujing" @click.stop="yujingClick(item)">
            <span>预警{{ item.warningCount }}次</span>
            <img :src="jiantou" alt="" />
          </div>
          <div class="xuncha" @click.stop="xunchaClick(item)">
            <span>巡查{{ item.patrolCount }}次</span>
            <img :src="jiantou" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <no-data v-else></no-data>
</template>
<script>
import api from '@/api/home/index';
import noData from '@/components/no-data'
export default {
  components: {
    noData
  },
  data () {
    return {
      jiantou: require('@/assets/img/home/xiangqingjiantou.png'),
      zhanwei: require('@/assets/img/login/zhanwei.png'),
      list1: [],
      scenicSpotId: null,
      form: {
        scenicSpotId: 0,
        name: ''
      },
      name: ''
    };
  },
  created () {
    this.form.scenicSpotId = this.$route.query.id;
    this.name = this.$route.query.name;
    this.getlist();
  },
  methods: {
    yujingClick (item) {
      this.$router.push({
        name: 'homexiangxilist',
        query: {
          state: 'yujing',
          id: item.id,
          name: item.name
        }
      });
    },
    xunchaClick (item) {
      this.$router.push({
        name: 'homexiangxilist',
        query: {
          state: 'xuncha',
          id: item.id,
          name: item.name
        }
      });
    },
    getlist () {
      api.jingqulist(this.form).then((res) => {
        this.list1 = res.data;
        console.log(this.list1);
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import url("~@/assets/less/conment.less");

.xiangxiBox {
  padding: 0px 10px 0px 10px;

  .list {
    margin-top: 11px;
    height: 130px;
    background: #ffffff;
    border-radius: 11px;
    display: flex;
    align-items: center;

    img {
      width: 111px;
      height: 96px;
      margin-left: 4px;
      // margin-left: 15px;
      border-radius: 8px;
    }

    .cont {
      // margin-left: 10px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #1c91ff;
      height: 95px;
      width: 100%;
      box-sizing: border-box;
      padding-left: 8px;
      padding-right: 14px;

      span {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #363a44;
      }

      .shangxia();

      .shebei {
        height: 25px;
        width: 100px;
        background: rgba(28, 145, 255, 0.08);
        border-radius: 11.5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .bottom {
        .liangbian();

        // width: 228px;
        .yujing {
          height: 25px;
          min-width: 100px;
          background: rgba(28, 145, 255, 0.08);
          border-radius: 11.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 2px;
          span{
            font-size: 13px;
            color: #1c91ff;
            overflow: hidden;
            white-space: nowrap;
            max-width: 90px;
          }

          img {
            width: 13px;
            height: 13px;
          }
        }

        .xuncha {
          height: 25px;
          width: 100px;
          background: rgba(28, 145, 255, 0.08);
          border-radius: 11.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          span{
            font-size: 13px;
            color: #1c91ff;
            overflow: hidden;
            white-space: nowrap;
            max-width: 90px;
          }

          img {
            width: 13px;
            height: 13px;
          }
        }
      }
    }
  }
}
</style>
