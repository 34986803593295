<template>
  <div class="myBox">
    <div class="userInformation">
      <div class="xiangqing">
        <div class="head">
          <img :src="head" alt="" />
        </div>
        <div class="xinxi">
          <div class="name">{{ name }}</div>
          <div class="jobTitle">{{ companyName }}</div>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="box" @click="go(item.name)" v-for="(item, index) in list" :key="index">
        <img class="move_img" :src="item.icon" alt="" />
        <div class="name">{{ item.name }}</div>
        <div class="hongdian" v-show="item.show && noReade">{{ noReade }}</div>
        <img class="move_jiantou" :src="jiantou" alt="" />
      </div>
    </div>
    <!-- <div class="set">
      <div class="box">
        <img :src="set" alt="" />
        <div>系统设置</div>
        <img class="i1" :src="jiantou" alt="" />
      </div>
    </div> -->
    <Nav></Nav>
  </div>
</template>
<script>
import api from '@/api/my/index';
import Nav from '@/components/nav/nav.vue';
import request from '@/utils/request'
export default {
  components: {
    Nav
  },
  data () {
    return {
      queryParams: {
        //1 全部 已读2,未读3
        type: 1,
        page: 1,
        pageSize: 100
      },
      head: require('../assets/img/my/head.png'),
      list: [
        {
          name: '查阅记录',
          icon: require('../assets/img/my/record.png'),
          show: false
        },
        {
          name: '发布历史',
          icon: require('../assets/img/my/history.png'),
          show: false
        },
        {
          name: '我的消息',
          icon: require('../assets/img/my/information.png'),
          show: true
        },
        {
          name: '我的收藏',
          icon: require('../assets/img/my/collect.png'),
          show: false
        },
        {
          name: '景区信息',
          icon: require('../assets/img/my/jingqu.png'),
          show: false
        }
        // {
        //   name: '查看浙里办首页',
        //   icon: require('../assets/img/my/jingqu.png'),
        //   show: false
        // },
        // {
        //   name: '查看浙里办游客参与',
        //   icon: require('../assets/img/my/jingqu.png'),
        //   show: false
        // },
        // {
        //   name: '查看浙里办上传记录',
        //   icon: require('../assets/img/my/jingqu.png'),
        //   show: false
        // }
      ],
      jiantou: require('../assets/img/my/jiantou.png'),
      set: require('../assets/img/my/set.png'),
      name: '',
      position: '',
      companyName: '',
      tasknum: 0,
      noticenum: 0,
    };
  },
  computed: {
    noReade () {
      return this.noticenum
    }
  },
  created () {
    //获取token上的信息
    this.name = localStorage.getItem('userName');
    this.companyName = localStorage.getItem('companyName');
  },
  mounted () {
    this.getGonggao()
  },
  methods: {
    //    公告更新
    getGonggao () {
      request
        .post('/announcement/list', this.queryParams)
        .then((res) => {
          if (res.code == '200' && res.success) {
            this.noticenum = res.data.total

          }
        })
    },
    go (item) {
      if (item == '查阅记录') {
        this.$router.push({
          name: 'myshenhe',
          query: {
            name: '查阅记录'
          }
        });
      } else if (item == '发布历史') {
        this.$router.push({
          name: 'fabu',
          query: {
            name: '发布历史'
          }
        });
      } else if (item == '我的消息') {
        this.$router.push({
          name: 'message',
          query: {
            name: '我的消息'
          }
        });
      } else if (item == '我的收藏') {
        this.$router.push({
          name: 'shoucang',
          query: {
            name: '我的收藏'
          }
        });
      } else if (item == '景区信息') {
        this.$router.push({
          name: 'jingqu',
          query: {
            name: '浙里办首页'
          }
        });
      } else if (item == '查看浙里办首页') {
        this.$router.push({
          name: 'zlbHome',
          query: {
            name: '浙里办游客'
          }
        });
      } else if (item == '查看浙里办游客参与') {
        this.$router.push({
          name: 'zlbYou',
          query: {
            name: '景区信息'
          }
        });
      } else if (item == '查看浙里办上传记录') {
        this.$router.push({
          name: 'zlbUploade',
          query: {
            name: '浙里办上传'
          }
        });
      }
    },
  }
};
</script>
<style lang="less" scoped>
.myBox {
  .userInformation {
    height: 125px;
    background: linear-gradient(0deg, #f6f7f8 -20%, #2093ff 100%);
    display: flex;
    align-items: center;

    .xiangqing {
      height: 64px;
      display: flex;
      margin-left: 21px;

      .head {
        width: 64px;
        height: 64px;
        background: #ffffff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        img {
          width: 35px;
          height: 31px;
        }
      }

      .xinxi {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 11px;

        .name {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }

        .jobTitle {
          color: #fff;
          font-size: 13px;
        }
      }
    }
  }

  .list {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .box {
      font-size: 18px;
      height: 64px;
      border-bottom: 1px solid #e8e9ec;
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 68.5px;

      > .name {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
      }

      .hongdian {
        padding: 0px 5px;
        border-radius: 30px;
        background: #ff4240;
        text-align: center;
        line-height: 17px;
        font-size: 12px;
        color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 37px;
      }
      .move_jiantou {
        position: absolute;
        top: 50%;
        right: 17px;
        transform: translateY(-50%);
      }
      .move_img {
        position: absolute;
        top: 35%;
        left: 34px;
      }
      img {
        &:nth-child(1) {
          width: 27.5px;
          height: 26.5px;
          vertical-align: middle;
        }

        &:last-child {
          height: 14px;
          width: 8px;
        }
      }

      .xian {
        height: 1px;
        width: 304px;
      }
    }
  }

  .set {
    width: 355px;
    height: 67px;
    background: #fff;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;

    > .box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      div {
        width: 240px;
        justify-self: flex-start;
      }

      img {
        &:nth-child(1) {
          width: 26px;
          height: 26px;
          margin-top: 5px;
        }

        &:last-child {
          height: 14px;
          width: 8px;
        }
      }
    }
  }
}

.box:last-child {
  border: none !important;
}
</style>
