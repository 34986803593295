<template>
  <div class="xiangqingBox">
    <div class="jiben" style="padding:20px 0px">
      <div class="infomation">
        <div class="kuai" style="margin: 0"></div>
        <span style="font-size:16px">基本信息</span>
        <div class="xian"></div>
      </div>
      <div class="list">
        <div class="con">
          <van-field label="预警设施" v-model="Data.facilityName" readonly />
        </div>
        <div class="xian"></div>
      </div>
      <div class="list">
        <!-- <div class="title">任务标题</div> -->
        <div class="con">
          <van-field label="预警设备" v-model="Data.equipmentCode" readonly></van-field>
        </div>
        <div class="xian"></div>
      </div>
      <div class="list">
        <!-- <div class="title">任务标题</div> -->
        <div class="con">
          <van-field v-model="Data.auditCompanyName" readonly label="查阅单位"></van-field>
        </div>
        <div class="xian"></div>
      </div>
    </div>
    <div class="jiben" style="padding:20px 0px">
      <div class="infomation">
        <div class="kuai" style="margin: 0"></div>
        <span style="font-size:16px">预警内容</span>
        <div class="xian"></div>
      </div>
      <div class="list">
        <div class="con">
          <van-field label="内容详情" rows="1" type="textarea" autosize="" readonly v-model="Data.warningContent">
          </van-field>
        </div>
        <div class="xian"></div>
      </div>
      <div class="list">
        <div class="con">
          <van-field label="预警时间" readonly v-model="Data.warningTime"></van-field>
        </div>

        <div class="xian"></div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api/waring/index';
export default {
  data () {
    return {
      form: { warningHandleId: null },
      Data: {}
    };
  },
  created () {
    this.form.warningHandleId = this.$route.query.id;
  },
  mounted () {
    this.getList();
  },
  methods: {
    getList () {
      api.warningHandleDetail(this.form).then((res) => {
        if (res.code == '200') {
          this.Data = res.data;
          console.log(this.Data);
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import url("~@/assets/less/conment.less");
@import url("~@/assets/less/vantChange.less");
.xiangqingBox {
  margin: 10px 10px 10px 10px;
}
.jiben {
  margin: 10px 0;
  &:nth-child(2) {
    .list {
      .shangxia();
    }
  }
  .jiben();
  .list {
    // height: 41px;
    align-items: flex-end;
    .van-cell {
      font-size: 18px;
      color: #686b73;
      background: transparent;
      padding: 0px;
    }
    .con {
      width: 100%;
      .van-cell {
        color: #363a44 !important;
        font-size: 18px;
        padding: 10px 0px;
      }
      .van-cell__value {
        color: #b3b5b9;
      }
      .popupBox {
        padding: 48px 30px 38px 30px;
        .title {
          align-self: center;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #363a44;
        }
        .shangxia();
        .xuanze {
          .van-checkbox {
            justify-content: space-between;
            &:first-child {
              margin: 0 0 0 0;
            }
            margin-top: 27px;
          }
        }
        .buttomBox {
          width: 100%;
          height: 49px;
          .liangbian();
          align-items: center;
          margin-top: 94.5px;
          font-family: PingFang SC;
          font-weight: 400;
          .close {
            height: 49px;
            width: 137.5px;
            border-radius: 24.5px;
            border: 1px solid #0085ff;
            color: #0085ff;
            font-size: 18px;
            text-align: center;
            line-height: 49px;
          }
          .sure {
            width: 137.5px;
            height: 49px;
            border-radius: 24.5px;
            text-align: center;
            line-height: 49px;
            background: #1c91ff;
            color: #fff;
            font-size: 18px;
          }
        }
      }
    }
  }
}
/deep/.van-cell::after {
  border-bottom: 0px;
}
/deep/.van-step--vertical:not(:last-child)::after {
  border-bottom: 0px;
}
/deep/.van-button {
  position: absolute;
  bottom: 27px;
  left: 0px;
  font-size: 20px;
  width: 90%;
  justify-content: center;
  margin-left: 17px;
  margin-right: 17px;
  box-sizing: border-box;
}
/deep/.van-field__label {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #b3b5b9;
  margin-right: 14px;
  width: 61px;
}
/deep/.van-field__control {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #363a44;
}
</style>
