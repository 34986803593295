<template>
  <div class="FormBox">
    <div>
      <div class="jiben">
        <div class="infomation">
          <div style="margin:0" class="kuai"></div>
          <span style="font-size:16px">基本信息</span>
          <div class="xian"></div>
        </div>
        <div class="list" v-for="(item, index) in list" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="con">{{ item.con }}</div>
          <div class="xian"></div>
        </div>
      </div>
      <div class="project">
        <div class="infomation">
          <div style="margin:0" class="kuai"></div>
          <span style="font-size:16px">巡查项目</span>
          <div class="xian"></div>
        </div>
        <div class="xiangqing">
          <div class="name">项目详情</div>
          <div class="con">
            <div class="shixiang" v-for="(item, index) in shixiang" :key="index">
              {{ item }}
            </div>
          </div>
          <div class="xian"></div>
        </div>
        <div class="time">
          <div class="title">发布时间</div>
          <div class="timer">{{ cretime }}</div>
          <div class="xian"></div>
        </div>
      </div>
    </div>
    <!-- <van-form v-else @submit="onSubmit">
      <div class="jiben">
        <div class="infomation">
          <div class="kuai"></div>
          <span>巡查详情</span>
          <div class="xian"></div>
        </div>
        <div class="list" v-for="(item, index) in list" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="con van-ellipsis" style="width: 60%">{{ item.con }}</div>
          <div class="xian"></div>
        </div>
      </div>
      <div class="jiben" style="margin-top: 10px; height: 185px">
        <div class="infomation">
          <div class="kuai"></div>
          <span>执行人员</span>
          <div class="xian"></div>
        </div>
        <div class="list" v-for="(item, index) in zhixing" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="con">{{ item.con }}</div>
          <div class="xian"></div>
        </div>
      </div>
      <div
        class="jiben"
        style="
          margin-top: 10px;
          display: table-cell;
          position: relative;
          top: 10px;
        "
      >
        <div class="infomation" style="margin-top: 10px">
          <div class="kuai"></div>
          <span>巡查反馈</span>
          <div class="xian"></div>
        </div>
        <div
          class="fankui"
          v-for="(item, index) in fankui"
          :key="index"
          style="heght: 280px"
        >
          <span class="fankuixiangqing">{{ item.termContent }} </span>
          <van-radio-group
            v-model="item.termState"
            disabled
            direction="horizontal"
          >
            <van-radio :name="1">
              正常
              <template #icon="props">
                <img
                  class="img-icon"
                  :src="props.checked ? activeIcon : inactiveIcon"
                />
              </template>
            </van-radio>
            <van-radio :name="2">
              异常
              <template #icon="props">
                <img
                  class="img-icon"
                  :src="props.checked ? activeIcon : inactiveIcon"
                />
              </template>
            </van-radio>
          </van-radio-group>
          <div class="fankuiImg">
            <span class="word">反馈图片</span>
            <div class="imgBox">
              <img :src="item.picList[0]" alt="" />
              <img style="margin-left: 10px" :src="item.picList[1]" alt="" />
            </div>
          </div>
          <div class="xian"></div>
        </div>
        <div class="result">
          <div>巡查结果</div>
          <div>{{ Data.patrolResult == 1 ? '正常' : '异常' }}</div>
          <div class="xian"></div>
        </div> -->
    <!-- <div class="con" v-if="Data.patrolResult == 1" style="maring-top: 10px">
          <span>反馈内容</span>
          <div>
            {{ Data.feedbackComment }}
          </div>
          <div class="xian"></div>
        </div> -->
    <!-- <van-field
          v-show="liuyanShow"
          v-model="Data.feedbackComment"
          class="feedback"
          label="反馈内容"
          autosize
          type="textarea"
          placeholder="请输入意见"
        /> -->
    <!-- <div class="con" v-else style="maring-top: 10px">
          <span>处理措施</span>
          <div>
            {{ Data.handleIdea }}
          </div>
          <div class="xian"></div>
        </div> -->
    <!-- </div>
      <div class="jiben" style="margin-top: 20px">
        <div class="infomation">
          <div class="kuai"></div>
          <span>审核进度</span>
          <div class="xian"></div>
        </div>
        <van-steps
          direction="vertical"
          :active="shenhe.length"
          active-color="#1C91FF"
          :active-icon="stepIcon"
        >
          <van-step v-for="(item, index) in shenhe" :key="index">
            <span>
              {{ item.auditCompanyName }}
              {{ item.modifiedTime.substring(11, 16) }}
            </span>
            <div class="shenheqingkuang">
              <div>
                {{ item.auditCompanyName }}：<span>{{
                  item.examineState == 0
                    ? '未审核'
                    : item.examineState == 3
                    ? '通过'
                    : '不通过'
                }}</span>
              </div>
              <img :src="item.examineState == 3 ? pass : nopass" alt="" />
            </div>
          </van-step>
        </van-steps>
      </div>
      <div class="jiben" style="margin-top: 10px">
        <div class="infomation">
          <div class="kuai"></div>
          <span>审核结果</span>
          <div class="xian"></div>
        </div>
        <van-radio-group v-model="form.examineState" direction="horizontal">
          <van-radio :name="4" @click="liuyan">
            不通过
            <template #icon="props">
              <img
                class="img-icon"
                :src="props.checked ? xuanzhongIcon : inactiveIcon"
              />
            </template>
          </van-radio>
          <van-radio :name="3" @click="noliuyan">
            通过
            <template #icon="props">
              <img
                class="img-icon"
                :src="props.checked ? xuanzhongIcon : inactiveIcon"
              />
            </template>
          </van-radio>
        </van-radio-group>
        <van-field
          v-show="liuyanShow"
          v-model="form.reason"
          class="rectify"
          label="整改意见"
          autosize
          type="textarea"
          placeholder="请输入整改意见"
        />
        <van-cell
          @click="lianshenPopup"
          v-show="liuyanShow"
          v-model="form.aduitDept"
          title="不通过项目"
          class="van-ellipsis"
          is-link
          >请选择项目</van-cell
        >
        <van-popup
          v-model="lianshenShow"
          round
          position="bottom"
          :style="{ height: '65%' }"
          close-icon-position="bottom-left"
        >
          <div class="popupBox">
            <div class="title">请选择单位</div>
            <div style="margin-top: 50px" class="xuanze">
              <van-checkbox-group v-model="form.aduitDept">
                <van-checkbox
                  v-for="(item, index) in lianshenList"
                  :key="index"
                  :name="item.id"
                  @click="bumenxuanzhong(item.checked, index, item.name)"
                  label-position="left"
                >
                  <span :class="item.checked ? 'lanse' : 'moren'">
                    {{ item.name }}</span
                  >
                  <template #icon="props">
                    <img
                      class="img-icon"
                      style="wdith: 21px; height: 21px"
                      :src="props.checked ? xuanze : weixuan"
                    />
                  </template>
                </van-checkbox>
              </van-checkbox-group>
            </div>
            <div class="buttomBox">
              <div class="close" @click="close1">取消</div>
              <div class="sure" @click="sure1">确认</div>
            </div>
          </div>
        </van-popup>
      </div>
    </van-form>
    <van-button
      round
      type="info"
      block
      native-type="submit"
      style="margin-top: 28px; margin-bottom: 28px; font-size: 20px"
      @click="tijiao"
      >提交</van-button
    > -->
  </div>
</template>
<script>
import api from '@/api/Patrol/index';
export default {
  data () {
    return {
      form: {
        reason: '', //留言
        examineType: 2, //审核类型：1预警、2日常巡查、3任务定制
        patrolId: '', //巡查id
        examineState: ''
      },
      form1: {
        patrolId: 0
      },
      list: [
        {
          title: '巡查编号',
          con: ''
        },
        {
          title: '巡查标题',
          con: ''
        },
        {
          title: '巡查单位',
          con: ''
        }
        // {
        //   title: '查阅单位',
        //   con: ''
        // }
      ],
      shixiang: [],
      zhixing: [
        {
          title: '人员姓名',
          con: ''
        },
        {
          title: '联系电话',
          con: ''
        }
      ],
      fankui: [],
      shenhejindu: [],
      activeIcon: require('@/assets/img/patrol/zhengchang.png'),
      inactiveIcon: require('@/assets/img/patrol/yichang.png'),
      xuanzhongIcon: require('@/assets/img/patrol/xuanzhong.png'),
      pass: require('@/assets/img/patrol/pass.png'),
      nopass: require('@/assets/img/patrol/nopass.png'),
      stepIcon: require('@/assets/img/patrol/stepIcon.png'),
      liuyanShow: true,
      cretime: '',
      Data: '',
      shenhe: ''
    };
  },
  created () {
    this.form1.patrolId = this.$route.query.id;
    this.form.patrolId = this.$route.query.id;
    this.getlist();
  },
  methods: {
    //获取巡查详情
    getlist () {
      api.xunchaxiangqing(this.form1).then((res) => {
        console.log(res);
        this.list[0].con = res.data.patrolCode;
        this.list[1].con = res.data.title;
        this.list[2].con = res.data.companyName;
        // this.list[3].con = res.data.auditCompanyName;
        this.zhixing[0].con = res.data.patrolPerson;
        this.zhixing[1].con = res.data.mobile;
        this.cretime = res.data.createTime;
        this.fankui = res.data.patrolTermVOList;
        this.Data = res.data;
        this.shixiang = res.data.patrolTermVOList.map((item) => {
          return item.termContent;
        });
        console.log(this.shixiang);
      });
    },

    liuyan () {
      this.liuyanShow = true;
    },
    noliuyan () {
      this.liuyanShow = false;
    },
  }
};
</script>
<style lang="less" scoped>
@import url("~@/assets/less/conment.less");

.FormBox {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;

  .jiben {
    padding: 10px 0;
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .infomation {
      padding: 10px 15px;
      display: flex;
      justify-content: flex-start;
      position: relative;

      .kuai {
        width: 5px;
        height: 19px;
        background: #1c91ff;
        margin-top: 3px;
      }

      .xian {
        width: 90%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
      }

      span {
        font-size: 19px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #363a44;
        margin-left: 10px;
        width: 90px;
      }
    }

    .list {
      // width: 100%;
      padding: 10px 15px 0 15px;
      display: flex;
      position: relative;
      //   height: 32px;
      flex-direction: row;
      justify-content: flex-start;

      // border-bottom: 1px solid #000;
      .title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }

      .con {
        color: #363a44;
        font-size: 16px;
        margin: 0 20px;
      }

      .xian {
        width: 90%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .fankui {
      height: 254px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 20px 10px;
      position: relative;

      .fankuixiangqing {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #363a44;
        line-height: 23px;
      }

      .van-radio-group--horizontal {
        justify-content: center;
      }

      .img-icon {
        height: 16px;
      }

      .fankuiImg {
        display: flex;
        flex-direction: column;
        height: 110px;
        justify-content: space-between;

        .word {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #686b73;
        }

        img {
          width: 87px;
          height: 75px;
          border-radius: 5px;
          border: none;
        }
      }

      .xian {
        .hengxian();
      }
    }

    .result {
      height: 33px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 16px;
      position: relative;

      div {
        &:first-child {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #686b73;
        }

        &:nth-child(2) {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #363a44;
        }
      }

      .xian {
        .hengxian();
      }
    }

    .feedback {
      font-size: 16px;

      /deep/.van-cell__value {
        margin-top: 0;
        background: transparent;
        padding: 0;
        border-radius: 0;
      }
    }

    // .con {
    //   font-size: 16px;
    //   span {
    //     font-size: 16px;
    //     font-family: PingFang SC;
    //     font-weight: 400;
    //     color: #686b73;
    //   }
    //   div {
    //     width: 237px;
    //     height: 73px;
    //     font-size: 16px;
    //     font-family: PingFang SC;
    //     font-weight: 400;
    //     color: #363a44;
    //   }
    //   .xian {
    //     .hengxian();
    //   }
    // }
    .tu {
      .hezibj15px();
      .shangxia();
      height: 121px;

      img {
        .imgBox {
          .liangbian();
        }

        width: 95px;
        height: 82px;
        border-radius: 5px;

        &:last-child {
          margin-left: 10px;
        }
      }
    }

    .van-radio-group--horizontal {
      justify-content: center;
      padding: 37px;
    }

    .img-icon {
      height: 18px;
    }

    .van-steps {
      padding: 21px 0 0 32px;

      .shenheqingkuang {
        margin-top: 10px;
        height: 72px;
        background: #f6f7f8;
        border-radius: 8px;
        color: #686b73;
        font-size: 18px;
        .zhongjian();
        .liangbianfeng();

        span {
          color: #363a44;
        }

        img {
          width: 22px;
          height: 22px;
        }
      }

      .van-step--vertical:not(:last-child)::after {
        border-bottom-width: 0px;
      }
    }

    .rectify {
      flex-direction: column;
    }
  }

  .project {
    margin-top: 10px;
    // height: 319px;
    padding: 15px 0;
    background: #fff;
    border-radius: 10px;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-around;

    > .infomation {
      padding-left: 10px;
      padding-right: 15px;
      display: flex;
      justify-content: flex-start;
      position: relative;
      height: 32px;

      .kuai {
        width: 5px;
        height: 19px;
        background: #1c91ff;
        margin-top: 3px;
      }

      .xian {
        width: 90%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      span {
        font-size: 19px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #363a44;
        margin-left: 10px;
        width: 90px;
      }
    }

    .xiangqing {
      min-height: 20px;
      display: flex;
      justify-content: space-between;
      margin: 10px 15px;
      position: relative;

      .name {
        font-size: 16px;
        color: #686b73;
      }

      .con {
        width: 234px;
        font-size: 16px;
        color: #363a44;
        line-height: 23px;
        max-height: 300px;
        overflow: auto;
        margin-bottom: 10px;

        .shixiang {
          margin-top: 19px;

          &:first-child {
            margin-top: 0px;
          }
        }
      }

      .xian {
        width: 100%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .time {
      margin-left: 15px;
      margin-right: 15px;
      position: relative;
      height: 36px;
      display: flex;
      //   justify-content: space-between;
      color: #686b73;

      .title {
        font-size: 16px !important;
      }

      > .timer {
        align-self: flex-start;
        color: #363a44;
        font-size: 16px;
        margin-left: 30px;
      }

      .xian {
        width: 100%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

/deep/.van-radio {
  font-size: 18px;
}

/deep/.van-step__circle {
  width: 12px;
  height: 12px;
}

/deep/.van-step--finish {
  color: #1c91ff;
}

/deep/ .van-cell__value {
  margin-top: 21px;
  background: #f6f7f8;
  padding: 10px 10px;
  border-radius: 10px;
}
</style>
