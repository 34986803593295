import axios from "axios";
import qs from "qs";
import Vue from "vue";
import router from '../router/index'
let vm = new Vue

import apiList from './api_list'

let request = axios.create({
  baseURL: 'https://wenlv.borunspace.com:8001/', // 测试环境
  // baseURL: 'https://tlxyt.tonglu.gov.cn:82', // 生产环境
  timeout: 100000
})


request.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    console.log(config)
    config.headers.token =
      localStorage.getItem("tongluUser") != null
        ? localStorage.getItem("tongluUser")
        : "";
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  },

);
// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    //错误code码

    if (response.data.code === 1) {
      vm.$message({
        message: response.data.msg,
        type: "error",
      });
      return Promise.reject();
    }

    const errCodes = [401, 403];

    if (errCodes.indexOf(response.data.code) != -1) {
      vm.$message({
        message: response.data.msg,
        type: "error",
      });
      return response.data;
    }

    return response.data;

  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

// let baseURL = "http://192.168.3.155:8083";
// let token = sessionStorage.getItem('1')

export default {
  request: request,
  post(apiName, data) {
    let url = apiList[apiName];
    return request({
      method: 'post',
      // baseURL: baseURL,
      url: url,
      data: qs.stringify(data),
    })
  },
  post2(apiName, data) {
    let url = apiList[apiName];
    return request({
      method: "post",
      // baseURL: baseURL,
      url: url,
      data: data,
    });
  },
  get(apiName, data) {
    let url = apiList[apiName];
    return request({
      method: "get",
      // baseURL: baseURL,
      url: url,
      params: data,
    });
  },
  get2(apiName, data) {
    let url = apiList[apiName];
    return request({
      method: "get",
      // baseURL: baseURL,
      url: url,
      data,
    });
  },
}