<template>
  <div class="messageBox">
    <div class="list" style="border-bottom:0.5px solid #E8E9EC" @click="shenpi">
      <div class="leftBox">
        <img :src="shixiang" alt="" />
        <div class="word">
          <div class="title">待查阅</div>
          <div class="cont">您有任务未查阅，请及时查阅</div>
        </div>
      </div>
      <div class="righticon">
        <div class="time">{{tasktime}}</div>
        <!-- <div v-if="tasknum" class="hongdian">{{tasknum}}</div> -->
      </div>
    </div>
    <div class="list" @click="xiangxi">
      <div class="leftBox">
        <img :src="gonggao" alt="" />
        <div class="word">
          <div class="title">公告更新</div>
          <div class="cont">公告刚刚进行了更新，请及时查看</div>
        </div>
      </div>
      <div class="righticon">
        <div class="time">{{noticetime}}</div>
        <div v-if="noticenum" class="hongdian">{{noticenum}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api/my/index';
import request from '@/utils/request'
import moment from 'moment';
export default {
  data () {
    return {
      queryParams: {
        //1 全部 已读2,未读3
        type: 1,
        page: 1,
        pageSize: 100
      },
      shixiang: require('@/assets/img/my/shixiang.png'),
      gonggao: require('@/assets/img/my/gonggao.png'),
      youshixiang: require('@/assets/img/my/youshixiang.png'),
      yougonggao: require('@/assets/img/my/yougonggao.png'),
      tasktime: moment().format('MM-DD'),
      noticetime: moment().format('MM-DD'),
      tasknum: 0,
      noticenum: 0,
    };
  },
  mounted () {
    this.getGonggao()
  },
  methods: {
    //    公告更新
    getGonggao () {
      request
        .post('/announcement/list', this.queryParams)
        .then((res) => {
          if (res.code == '200' && res.success) {
            this.noticenum = res.data.total

          }
        })
    },
    xiangxi () {
      this.$router.push({
        name: 'messagexiangxi',
        query: {}
      });
    },
    shenpi () {
      this.$router.push({
        name: 'shenpi'
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import url("~@/assets/less/conment.less");

.messageBox {
  background: #ffffff;
  margin: 10px 0 0 0;

  .list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;

    &:first-child {
      margin-top: 15px;
    }

    .liangbian();

    .leftBox {
      .liangbianfeng();
      align-items: center;

      img {
        height: 50px;
        width: 50px;
        // border-radius: 50%;
      }

      .word {
        margin-left: 10px;

        .title {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #363a44;
        }

        .cont {
          // margin-top: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #9da9b8;
        }
      }
    }
    .righticon {
      margin: 0 10px 0 0;
    }
    .hongdian {
      padding: 0px 5px;
      border-radius: 30px;
      background: #ff4240;
      text-align: center;
      line-height: 17px;
      font-size: 12px;
      color: #fff;
      position: relative;
      top: 8px;
      left: 2px;
    }
    .time {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #b3b5b9;
      align-self: center;
    }
  }
}
</style>
