<template>
  <div class="loginBox">
    <div class="imgBox">
      <van-image
        fit="cover"
        height="100%"
        width="100%"
        :src="backgroundImage"
      />
    </div>
    <div class="login">
      <div class="loginBox">
        <span class="title">欢迎登录桐庐县文旅新业态监管系统</span>
        <van-field
          v-model="form.userName"
          :left-icon="user"
          placeholder="请输入您的用户名"
          clearable
        />
        <van-field
          :left-icon="passwordIcon"
          v-model="form.password"
          placeholder="请输入密码"
          :type="showPWD ? 'text' : 'password'"
        >
          <template slot="right-icon">
            <div
              :class="eyea ? 'eyeaclose' : 'eyeaopen'"
              style="width: 16px; height: 9px"
              @click="clickEyea"
            ></div>
          </template>
        </van-field>
        <van-checkbox v-model="checked">下次自动登录</van-checkbox>
      </div>
      <van-button type="info" @click="login()">登录</van-button>
    </div>
  </div>
</template>

<script>
import JSEncrypt from "jsencrypt";
const publicKey =
  "MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAKoR8mX0rGKLqzcWmOzbfj64K8ZIgOdH\n" +
  "nzkXSOVOZbFu/TJhZ7rFAN+eaGkl3C4buccQd/EjEsj9ir7ijT7h96MCAwEAAQ==";
import api from "@/api/methods";
import request from "@/utils/request";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      showPWD: false,
      property: "value",
      form: {
        userName: "",
        password: "",
        loginPorts: 2,
      },
      backgroundImage: require("../assets/img/login/background.webp"),
      user: require("../assets/img/login/user.png"),
      passwordIcon: require("../assets/img/login/password.png"),
      eyesUrl: null,
      eyea: true,
      checked: false,
      accountId: "",
      form1: {
        authCode: "",
        loginPorts: 2,
      },
    };
  },
  created() {
    this.eyesUrl = this.eyeaclose;
    this.showPWD = false;
    // dd.getConfig()
    //   .then((res) => {
    //     // console.log(res);
    //     // alert(JSON.stringify(res));
    //   })
    //   .catch((err) => {});

    dd.getAuthCode({
      corpId: "",
    })
      .then((res) => {
        this.form1.authCode = res.code;
        // console.log(res.code);
        // alert(res.code);
        // alert(JSON.stringify(res));
        api.ddlogin(this.form1).then((res) => {
          if (res.code == "200") {
            this.$toast("登录成功");
            // alert('获取成功 ，跳转首页');
            // this.$router.push({ path: "./home" });
            // alert('怎么不跳转');
            localStorage.setItem("type", res.data.type);
            localStorage.setItem("tongluUser", res.data.token);
            localStorage.setItem("companyId", res.data.companyId);
            localStorage.setItem("userName", res.data.userName);
            localStorage.setItem("companyName", res.data.companyName);
            localStorage.setItem("viewVideo", res.data.viewVideo); // 是否有权限显示视频
            // alert('把信息存进缓存');
            localStorage.setItem("token", res.data.token);
            this.$router.replace({ path: "/home" });
          } else {
            // alert(res.code);
            this.accountId = res.code;
            this.$toast(res.message);
          }
        });
      })
      .catch((err) => {
        alert(err);
      });
  },
  mounted() {},
  methods: {
    // 加密
    encrypt(txt) {
      const encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey); // 设置公钥
      return encryptor.encrypt(txt); // 对数据进行加密
    },
    ddlogin() {},
    clickEyea() {
      this.showPWD = !this.showPWD;
      this.eyea = !this.eyea;
    },
    login() {
      if (this.form.userName == "") {
        this.$toast("请输入用户名");
      } else if (this.form.password == "") {
        this.$toast("请输入密码");
      } else {
        // request.post("/rsa/getKey").then((keyRes) => {
        //   var encryptor = new Encrypt();
        //   encryptor.setPrivateKey(keyRes.data);
          let form = { ...this.form };
          form.password = this.encrypt(this.form.password);
          api.login({ ...form, accountId: this.accountId }).then((res) => {
            if (res.id) {
              // setTimeout(() => {
              // request.post('/login/up', { ...form, accountId: this.accountId }).then(logRes => {
              this.$toast("登录成功");
              localStorage.setItem("token", res.token);
              localStorage.setItem("tongluUser", res.token);
              localStorage.setItem("companyId", res.companyId);
              localStorage.setItem("type", res.type);
              localStorage.setItem("userName", res.userName);
              localStorage.setItem("viewVideo", res.viewVideo); // 是否有权限显示视频
              // this.$router.push({ path: "./home" });
              this.$router.replace({ path: "/home" });
              localStorage.setItem("companyName", res.companyName);
              //   })
              // }, 1000)
            } else {
              this.$toast(res.message);
            }
          });
        // });
      }
    },
  },
  watch: {
    showPWD(newVal) {
      console.log(newVal);
      if (newVal) {
        this.eyesUrl = this.eyeaopen;
      } else {
        this.eyesUrl = this.eyeaclose;
      }
    },
  },
};
</script>
<style lang="less">
.loginBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .imgBox {
    width: 100%;
    height: 336px;
    img {
    }
    display: inline-block;
  }
  .login {
    top: 20vh;
    min-height: 400px;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
  }
  .loginBox {
    width: 340px;
    height: 261px;
    background: #fff;
    border-radius: 5%;
    border: 1px solid #000;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .title {
      width: 164px;
      // height: 20px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #2a96fb;
    }
    .van-field {
      width: 80%;
      input:-intenal-autofill-selected {
        background-color: #fff;
      }
    }
    .van-checkbox {
      .van-checkbox__label {
        color: #b3b5b9;
        font-size: 12px;
      }
    }
  }
  .van-button {
    position: absolute;
    bottom: 50px;
    width: 250px;
    height: 40px;
    background: #2093fe;
    box-shadow: 0px 0px 3px 0px rgba(218, 229, 237, 0.1);
    border-radius: 20px;
    font-weight: 400;
    font-size: 18px;
  }
}
.eyeaclose {
  background: url("../assets/img/login/eyeaclose.png");
  background-size: 100% 100%;
  border: none !important;
}
.eyeaopen {
  background: url("../assets/img/login/eyeaopen.png");
  background-size: 100% 100%;
}
</style>
