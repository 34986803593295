
export default {
  list: '/api/app/home/list', // 景区列表
  importantList: '/api/app/my/collectionList', //重点关注列表
  editFocus: '/api/app/my/myCollection', //取消或者关注
  getAnnouncementInfo: '/api//app/home/getAnnouncementInfo',//获取公告
  toplist: '/api/app/home/list2', //头部列表
  jingqulist: '/api/app/home/list3',//景区列表二级页面
  map1: '/api/app/home/scenicSpotMapList',//景区地图一级数据
  map2: '/api/app/home/facilityMapList',//景区地图二级数据
  map3: '/api/app/home/equipmentMapList',//景区地图三级数据
  warningHandleRecord: '/api/app/home/warningHandleRecord',//景区列表二级页面预警记录
  patrolRecord: '/api/app/home/patrolRecord', //景区列表二级页面巡查记录
}