<template>
  <div class="bigBox">
    <van-field
      v-model="reason"
      rows=""
      autosize
      type="textarea"
      placeholder="请输入退回原因（300字以内）"
      maxlength="300"
    />
    <van-button
      :disabled="prohibit"
      @click="submit"
      class="submit"
      round
      type="info"
      >提交</van-button
    >
  </div>
</template>
<script>
import api from '@/api/task/index';
export default {
  data() {
    return {
      reason: '',
      form: {
        reason: '',
        auditResult: 0,
        id: null
      },
      prohibit: true
    };
  },
  created() {
    this.form.id = this.$route.query.id;
    console.log(this.$route.query);
    console.log(this.form.id);
  },
  methods: {
    submit() {
      this.form.auditResult = 4;
      api.auditTask(this.form).then((res) => {
        if (res.code == '200') {
          this.$toast('提交成功');
          this.$router.push({
            name: 'Task'
          });
        } else {
          this.$toast(res.message);
        }
      });
    }
  },
  watch: {
    reason(nv, ov) {
      this.form.reason = this.reason;
      if (nv == '') {
        this.prohibit = true;
      } else {
        this.prohibit = false;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.bigBox {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  .submit {
    margin-top: 120px;
    width: 95%;
    height: 48px;
    background: #1c91ff;
    border-radius: 24px;
  }
}
/deep/.van-cell {
  margin-top: 10px;
  width: 369px;
  height: 234px;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px 20px;
}
/deep/.van-field__control {
  font-size: 16px;
}
</style>
