export default {
  auditRecord: '/api/app/my/consultRecord', //审核记录列表
  releaseHistory: '/api/app/my/releaseHistory', //发布历史列表
  message: '/api/app/my/message', //我的消息
  collectionList: '/api/app/my/collectionList',//我的收藏
  scenicSpotDetail: '/api/app/my/scenicSpotDetail',//景区信息
  announcement: '/api/app/my/announcement',//公告更新
  auditNotice: '/api/app/my/auditNotice',//审批详情
  myCollection: '/api/app/my/myCollection',//点击收藏
  taskNotice: '/api/app/my/taskNotice',//任务

}