<template>
  <div class="patrolBox">
    <Search v-model="form" @changeclickSearch="clickSearch" @changeonConfirm="onConfirm"></Search>
    <div class="option">
      <div @click="yishenClick('已审核')" :class="yishenShow ? 'yishenheClick' : 'yishenheNoClick'">
        <div class="icon" v-show="yiShow"></div>
        <div class="word">
          <!-- <div class="dian" v-show="yishenNumber!=0">{{ yishenNumber }}</div> -->
          已处理
        </div>
      </div>
      <div @click="xunClick('')" :class="xunShow ? 'xunClick' : 'yishenheNoClick'">
        <div class="icon"></div>
        <div class="word">
          <div class="dian" v-show="xunNumber!=0">{{ xunNumber }}</div>
          待处理
        </div>
      </div>
    </div>

    <div class="list" v-if="Data1.length > 0">
      <van-list v-model="loading" :finished="finished" :finished-text="text" @load="onLoad" :offset="0" :immediate-check="false">
        <div class="box" @click="xiangqing(item)" v-for="(item, index) in Data1" :key="index">
          <div class="contentBox">
            <div class="word">
              <div class="title">{{ item.facilityName || '--' }}</div>
              <div class="biaoti van-ellipsis" style="width: 90%; margin-top: 10px">
                预警设备:{{ item.equipmentCode || '--' }}
              </div>
              <div class="biaoti van-ellipsis" style="width: 90%">
                预警项：{{ item.monitorTerm ||'--'}}
              </div>

              <div class="time">
                {{
                  form.examineState == 3
                    ? '解除时间：'
                    : form.examineState == 2
                    ? '上传时间：'
                    : '预警时间：'
                }}{{
                   form.examineState == 3
                    ? item.removeTime || '--'
                    : form.examineState == 2
                    ? item.createTime || '--'
                    : item.warningTime || '--' 
                }}
              </div>
            </div>
            <div v-if="xunShow" class="chakan">
              <van-button type="info" @click.stop="chakan(item)">查看</van-button>
            </div>
            <img v-else :src="item.imgUrl" alt="景区" />
          </div>
        </div>
      </van-list>
    </div>
    <no-data v-else></no-data>
  </div>

</template>
<script>
import api from '@/api/waring/index'
import noData from '@/components/no-data'
import Search from '@/components/Search'
export default {
  components: {
    noData,
    Search
  },
  data () {
    return {
      loading: false,
      finished: false,
      text: '没有更多了',
      form: {
        keyWord: '',
        examineState: 3,
        pageNum: 1,
        pageSize: 10,
        startTime: "",
        endTime: "",
      },
      rili: false,
      riqi: require('@/assets/img/my/xiajiantou.png'),
      Data1: [],
      searchIcon: require('@/assets/img/patrol/search.png'),
      yishenShow: true,
      yishendian: true,
      daiShow: false,
      daishenNumber: 2,
      daishendian: true,
      xunShow: false,
      yishenNumber: 0,
      xunNumber: 0,
      xundian: true,
      user: require('@/assets/img/task/user.png'),
      gaojin: require('@/assets/img/task/gaojin.png'),
      zhiding: require('@/assets/img/task/zhiding.png'),
      maxDate: new Date(new Date().valueOf() + 24 * 600 * 600 * 10000),
      minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    }
  },
  created () {
    this.yiShow = true
    if (this.yishenNumber == 0 || this.yishenShow == true) {
      this.yishendian = false
    } else if (this.daishenNumber == 0 || this.daiShow == true) {
      this.daishendian = false
    }
  },
  mounted () {
    if (this.$route.query.type) {
      this.form.examineState = 1
      //  this.finished = false;
      this.text = ''
      this.form.pageNum = 1
      this.yishenShow = false
      this.daiShow = false
      this.xunShow = true
      this.xundian = false
    }
    this.getList()
  },
  methods: {
    onLoad () {
      this.form.pageNum++
      this.loading = false
      api.warnList(this.form).then((res) => {
        if (res.rows.length !== 0) {
          for (var i in res.rows) {
            this.Data1.push(res.rows[i])
          }
        } else if (res.rows.length == 0) {
          this.finished = true
          return
        }
      })
    },
    //获取列表数据
    getList () {
      this.Data1 = []
      api.warnList(this.form).then((res) => {
        this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
        })
        if (res.code == '200') {
          this.Data1 = res.data.rows
        }
      })
    },

    chakan (item) {
      this.$router.push({
        name: 'waringForm',
        query: {
          name: '预警详情页面',
          id: item.id,
          state: item.examineState,
        },
      })
    },

    xiangqing (item) {
      //已审核跳转页面
      if (this.form.examineState == 3) {
        this.$router.replace({
          name: 'waringchakan',
          query: {
            name: '预警已审核的页面',
            id: item.id,
            state: item.examineState,
          },
        })
      } else {
        //未审核跳转页面
        this.$router.push({
          name: 'waringForm',
          query: {
            name: '预警审核',
            id: item.id,
            state: item.examineState,
          },
        })
      }
    },


    clickSearch () {
      this.form.pageNum = 1
      this.getList()
    },
    onConfirm () {
      this.getList()
    },
    yishenClick () {
      this.form.keyWord = "",
        this.form.startTime = "",
        this.form.endTime = "",
        this.text = ''
      this.form.examineState = 3
      // this.finished = false;
      this.form.pageNum = 1
      this.getList()
      this.$router.replace({
        path: '/waring/list',
      })
      this.yishenShow = true
      this.yishendian = false
      this.daiShow = false
      this.xunShow = false
    },
    daishenClick () {
      this.yishenShow = false
      this.daishendian = false
      this.daiShow = true
      this.xunShow = false
      this.form.examineState = 2
      this.getList()
    },
    xunClick () {
      this.form.keyWord = "",
        this.form.startTime = "",
        this.form.endTime = "",
        this.text = ''
      this.form.examineState = 1
      //this.finished = false;
      this.form.pageNum = 1
      this.yishenShow = false
      this.daiShow = false
      this.xunShow = true
      this.xundian = false
      this.getList()
      this.$router.push({
        path: '/waring/list',
        query: {
          type: 1,
        },
      })
    },
    jialing (s) {
      return s < 10 ? '0' + s : s
    },
    formatDate (date) {
      return `${date.getFullYear()}-${this.jialing(
        date.getMonth() + 1
      )}-${this.jialing(date.getDate())}`
    },

  },
}
</script>
<style lang="less" scoped>
@import url("~@/assets/less/conment.less");

.patrolBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .search {
    margin-top: 10px;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 10px;
    .liangbian();

    .van-field {
      border-radius: 18px;
      align-items: center;
      margin-right: 10px;
    }

    .van-button {
      background: #fff;
      border: none;
      color: #1c8ffb;
      width: 67px;
      height: 40px;
      border-radius: 18px;
      padding: 0 0 0 0;

      .van-button__content {
        .van-icon__image {
          width: 12px;
          height: 12px;
        }
      }

      .van-button__content {
        .van-button--default {
          color: #1c8ffb !important;
        }
      }
    }
  }

  .option {
    height: 50px;
    border-radius: 25px;
    background: #eeeeee;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    margin: 10px 10px 0px 10px;

    .box {
      width: 143px;
      height: 42px;
      border-radius: 36px;
      background: #1c91ff;
      color: #fff;
      font-size: 15px;
      box-shadow: 0px 4px 4px rgba(63, 141, 235, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .iconstyle {
      width: 16px;
      height: 16px;
      background-size: 100% 100%;
      margin-top: 3px;
    }

    .dianstyle {
      width: 15px;
      height: 15px;
      background: #e84b4b;
      border: 1px solid #dd4646;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      right: 36px;
      top: 0px;
      line-height: 15px;
      font-size: 9px;
      color: #fff;
    }

    .yishenheClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("~@/assets/img/patrol/yishenhe.png");
      }

      .word {
        margin-left: 10px;
      }
    }

    .yishenheNoClick {
      width: 143px;
      height: 36px;
      color: #333333;
      font-weight: 500;
      font-size: 15px;
      font-family: PingFang SC;
      line-height: 38px;

      .word {
        position: relative;
        text-align: center;

        .dian {
          .dianstyle();
        }
      }
    }

    .daishenheClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("~@/assets/img/patrol/daishenhe.png");
      }
    }

    .xunClick {
      .box();

      .icon {
        .iconstyle();
        background-image: url("~@/assets/img/patrol/daixuncha.png");
      }

      .word {
        margin-left: 10px;
      }
    }
  }

  .list {
    margin-top: 10px;
    height: 630px;
    overflow-y: auto;
    padding: 0 10px 10px 10px;

    .box {
      &:first-child {
        margin-top: 0px;
      }

      margin-top: 10px;
      height: 127px;
      border-radius: 10px;
      position: relative;
      background: #fff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .biaoshiStyle {
        position: absolute;
        left: 0;
        top: 0;
        width: 50px;
        height: 20px;
        color: #fff;
        border-radius: 10px 0px 10px 0px;
        font-size: 12px;
        text-align: center;
        line-height: 20px;
      }

      .lvbiaoshi {
        .biaoshiStyle();
        background: #50cb79;
        box-shadow: 0px 2px 1px 0px rgba(21, 146, 63, 0.2);
      }

      .huangbiaoshi {
        background: #ffac20;
        .biaoshiStyle();
        box-shadow: 0px 2px 1px 0px rgba(216, 137, 3, 0.2);
      }

      .hongbiaoshi {
        background: #ff6661;
        .biaoshiStyle();
        box-shadow: 0px 2px 1px 0px rgba(226, 57, 51, 0.2);
      }

      .contentBox {
        margin: 5px 18px 0px 18px;
        display: flex;
        justify-content: space-between;

        .imgBox {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 0px 1px 0px rgb(108, 107, 107);
          align-self: center;

          .gaojin {
            width: 100%;
            height: 100%;
          }

          .user {
            width: 20px;
            height: 18px;
          }
        }

        .word {
          font-family: PingFang SC;
          color: #686b73;
          font-size: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 79%;

          .title {
            font-size: 15px;
            font-weight: 600;
            color: #363a44;
          }
        }

        > img {
          height: 78.5px;
          width: 106px;
          border-radius: 8px;
          align-self: end;
        }

        .chakan {
          display: flex;
          flex-direction: column;
          justify-content: center;

          > .time {
            color: #686b73;
            font-size: 11px;
            align-self: flex-end;
          }

          .van-button {
            width: 100%;
            height: 23px;
            width: 61px;
            box-shadow: 0px 2px 3px 0px rgba(28, 128, 222, 0.2);
            border-radius: 10px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
