<template>
  <div class="yinhuanBox">
    <van-overlay :show="show" class="overlay" >
      <yhDetail />
    </van-overlay>
    <div class="date" :style="styleobj">
      <van-calendar
        title="日历"
        :poppable="false"
        :show-confirm="false"
        :show-mark="false"
        :show-title="false"
        :allow-same-day="true"
        color="#1C91FF"
        type="range"
        row-height="35"
        @confirm="onConfirm"
        :formatter="formatter"
        :min-date="minDate"
        :max-date="maxDate"
        :default-date="date"
      />
      <div class="zhankai" @click="zhankai">
        {{ zhankaiShow ? "展开" : "收起" }}
        <img
          :class="zhankaiShow ? 'noxuanzhuan' : 'xuanzhuan'"
          :src="jiantou"
          alt=""
        />
      </div>
    </div>
    <div class="jiben" style="margin-top: 15px">
      <div class="infomation">
        <div class="kuai"></div>
        <span>隐患排查列表</span>
      </div>
    </div>
    <div class="list">
      <div class="top">
        <p style="width: 40px; text-align: center">序号</p>
        <p style="width: 115px; text-align: center">风险隐患状况</p>
        <p style="text-align: center; width: 80px">包案领导</p>
        <p style="width: 40px; text-align: center">操作</p>
      </div>
      <div class="cont">
        <div class="contBox" v-for="(item, index) in list" :key="index">
          <p style="width: 40px; text-align: center">{{ index + 1 }}</p>
          <p class="van-ellipsis" style="width: 115px; text-align: center">
            {{ item.content }}
          </p>
          <p style="text-align: center; width: 80px">{{ item.contacts }}</p>
          <p
            style="color: #1c91ff; width: 40px; text-align: center"
            @click="xiangqing(item, index + 1)"
          >
            查看
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
import yhDetail from './xiangqing.vue';
import dayjs from "dayjs";
export default {
  components: {
    yhDetail
  },
  data() {
    let endTime = dayjs().format("YYYY-MM-DD");
    let startTime = dayjs().subtract(7, "days").format("YYYY-MM-DD");
    let startTimeDate = new Date(startTime);
    return {
      date: [startTimeDate, new Date()],
      queryParams: {
        scenicSpotId: 1,
        statType: 0,
        pageSize: 300,
        page: 1,
      },
      form: {
        startTime: "",
        endTime: "",
      },
      jiantou: require("@/assets/img/my/xiajiantou.png"),
      styleobj: {
        height: "-1%",
      },
      show: false,
      zhankaiShow: true,
      daochu: require("@/assets/img/yinhuan/daochu.png"),
      weixuan: require("@/assets/img/task/weixuan.png"),
      xuanzhong: require("@/assets/img/task/xuanze.png"),
      list: [],
      formdata: {},
      minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 5)),
      maxDate: new Date(),
    };
  },
  created() {
    console.log(this.$route, "router");
    // this.isOcen
    this.getlist();
  },
  mounted() {
    let that = this;
    window.addEventListener('popstate', function(e) {
      that.show = false
    }, false);
  },
  methods: {
    zhankai() {
      this.zhankaiShow = !this.zhankaiShow;
      console.log(this.zhankaiShow);
      if (this.zhankaiShow == false) {
        this.styleobj = {
          height: "500px",
        };
      } else {
        this.styleobj = {
          height: "150px",
        };
      }
    },
    xuanxiang(item, index) {
      this.list[index].check = !item;
    },
    onConfirm(date) {
      this.show = false;
      this.date = date;
      this.getlist();
    },
    formatter(day) {
      if (day.type === "start") {
        day.bottomInfo = "";
      } else if (day.type === "end") {
        day.bottomInfo = "";
      }
      return day;
    },
    xiangqing(i) {
      this.$store.commit("setPerilInfo", i);
      this.show = true;
      // this.$router.push({
      //   name: "yinhuanxiangxi",
      //   query: {
      //     name: "隐患详情",
      //   },
      // });
    },
    //隐患排查列表
    getlist() {
      let [startTime, endTime] = this.date;
      startTime = dayjs(startTime).format("YYYY-MM-DD");
      endTime = dayjs(endTime).format("YYYY-MM-DD");
      request({
        url: "app/home/patrolPageList",
        method: "get",
        data: {
          ...this.queryParams,
          startTime,
          endTime,
        },
      }).then((res) => {
        let data = res.data;
        this.list = data.records;
        this.total = data.total;
        this.queryParams.pageSize = data.pageSize;
        this.queryParams.page = data.current;
      });
    },
    closeOverlay() {
      this.show = false;
      this.$router.back();
    },
    pushHistory() {
      var state = {
        title: '',
        url: '/home' // 没有意义，只是为了占位
      };
      window.history.pushState(state, state.title, state.url);
    },
  },
  watch: {
    show: function(newVal, oldVal) {
      if(newVal == true) {
        this.pushHistory();
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import url("~@/assets/less/conment.less");
.overlay {
  background: #f5f5f5;
}
.yinhuanBox {
  margin: 10px 10px;
  overflow: hidden;
  .jiben {
    background: transparent;
  }
  .list {
    margin-top: 15px;
    background: #fff;
    border-radius: 11px;
    padding: 14px 10px 14px 10px;
    .top {
      height: 35px;
      display: flex;
      background-color: #e9f3fe;
      justify-content: space-around;
      align-items: center;
      p {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #363a44;
      }
    }
    .cont {
      height: calc(100vh - 340px);
      overflow: auto;
      // margin-top: 16px;
      div:nth-of-type(even) {
        background: #f6f7f8;
      }

      .contBox {
        //width: 100%;
        margin-top: 10px;
        height: 40px;
        // background: #f6f7f8;
        // border-radius: 5px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
        display: flex;
        justify-content: space-around;
        align-items: center;

        p {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #686b73;
        }

        &:first-child {
          margin-top: 0px;
        }

        img {
          width: 17px;
          height: 17px;
        }
      }
    }
  }

  .date {
    height: 150px;
    border-radius: 17px;
    padding: 10px 10px 30px 10px;
    background: #fff;
    position: relative;

    .zhankai {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #1c91ff;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      img {
        width: 10px;
        height: 6px;
      }
    }
  }
  .van-calendar__day--start {
    border-radius: 50% !important;
  }
}

.noxuanzhuan {
  transform: rotate(0deg) !important;
}

.xuanzhuan {
  transform: rotate(180deg) !important;
}
</style>
