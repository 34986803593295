<template>
  <div class="FormBox">
    <!-- <div v-if="xiangqingShow" class="jiben">
      <div class="infomation">
        <div class="kuai"></div>
        <span>基本信息</span>
        <div class="xian"></div>
      </div>
      <div class="list" v-for="(item, index) in list" :key="index">
        <div class="title">{{ item.title }}</div>
        <div class="con">{{ item.con }}</div>
        <div class="xian"></div>
      </div>
    </div>
    <div v-if="xiangqingShow" class="project">
      <div class="infomation">
        <div class="kuai"></div>
        <span>巡查项目</span>
        <div class="xian"></div>
      </div>
      <div class="xiangqing">
        <div class="name">项目详情</div>
        <div class="con">
          <div class="shixiang" v-for="(item, index) in shixiang" :key="index">
            {{ item }}
          </div>
        </div>
        <div class="xian"></div>
      </div>
      <div class="time">
        <div class="title">发布时间</div>
        <div class="timer">{{ cretime }}</div>
        <div class="xian"></div>
      </div>
    </div> -->
    <van-form v-if="!xiangqingShow" @submit="onSubmit">
      <div class="jiben">
        <div class="infomation">
          <div class="kuai"></div>
          <span>任务详情</span>
          <div class="xian"></div>
        </div>
        <div class="list" v-for="(item, index) in list" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="con"  style="word-break: break-all">{{ item.con }}</div>
          <div class="xian"></div>
        </div>
        <div class="list">
          <div class="title">任务内容</div>
          <div class="con" style="word-break: break-all">
            {{ Data.comment }}
          </div>
          <div class="xian"></div>
        </div>

        <div class="list">
          <div class="title">任务截止时间</div>
          <div class="con">{{ Data.taskTerm }}</div>
          <div class="xian"></div>
        </div>
      </div>
      <div class="jiben" style="margin-top: 10px">
        <div class="infomation">
          <div class="kuai"></div>
          <span>处理人员</span>
          <div class="xian"></div>
        </div>
        <div class="list" v-for="(item, index) in zhixing" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="con">{{ item.con }}</div>
          <div class="xian"></div>
        </div>
      </div>
      <div class="jiben" style="margin-top: 10px">
        <div class="infomation">
          <div class="kuai"></div>
          <span>任务反馈</span>
          <div class="xian"></div>
        </div>
        <!-- <van-field v-model="Data.feedbackComment" rows="1" autosize label="反馈内容" type="textarea" disabled /> -->
        
        <div class="list">
          <div class="title">反馈内容</div>
          <div class="con"  style="word-break: break-all">
            {{ Data.feedbackComment }}
          </div>
          <div class="xian"></div>
        </div>

        <div class="tu">
          <span>反馈图片</span>
          <div class="imgBox">
            <img v-for="(item, index) in Data.taskImageEntityList" :key="index" :src="item.imageUrl" alt="" />
          </div>
        </div>
      </div>
      <div class="bottomBox" v-if="examineState == 3">
        <van-button round type="info" @click="back" class="back">退回</van-button>
        <van-button round type="info" @click="read" class="read">已阅</van-button>
      </div>

      <!-- <div class="jiben" style="margin-top: 10px">
        <div class="infomation">
          <div class="kuai"></div>
          <span>审核进度</span>
          <div class="xian"></div>
        </div> -->
      <!-- <div
          v-if="this.Data.examineCompanyFlowList == null"
          style="font-size: 20px"
        >
          暂无审核进度
        </div> -->
      <!-- <van-steps
          direction="vertical"
          :active="shenhejindu.length"
          active-color="#1C91FF"
          :active-icon="stepIcon"
        >
          <van-step
            v-for="(item, index) in this.Data.examineCompanyFlowList"
            :key="index"
          >
            <p>{{ item.operateTime }}{{ item.title }}</p>
            <div class="shenheqingkuang">
              <div>
                {{ item.step
                }}<span>{{ item.examineState == 3 ? '通过' : '不通过' }}</span>
              </div>
              <img :src="item.examineState == 3 ? pass : nopass" alt="" />
            </div>
          </van-step>
        </van-steps>
      </div> -->
      <!-- <div class="jiben" style="margin-top: 10px">
        <div class="infomation">
          <div class="kuai"></div>
          <span>审核结果</span>
          <div class="xian"></div>
        </div>
        <van-radio-group v-model="form.auditResult" direction="horizontal">
          <van-radio :name="4" @click="liuyan">
            不通过
            <template #icon="props">
              <img
                class="img-icon"
                :src="props.checked ? xuanzhongIcon : inactiveIcon"
              />
            </template>
          </van-radio>
          <van-radio :name="3" @click="noliuyan">
            通过
            <template #icon="props">
              <img
                class="img-icon"
                :src="props.checked ? xuanzhongIcon : inactiveIcon"
              />
            </template>
          </van-radio>
        </van-radio-group>
        <van-field
          v-show="liuyanShow"
          v-model="form.reason"
          label="整改意见"
          class="rectify"
          type="textarea"
          autosize
          placeholder="请输入整改意见"
        />
      </div> -->
    </van-form>
    <!-- <van-button
      v-if="!xiangqingShow"
      round
      type="info"
      block
      native-type="submit"
      @click="tijiao()"
      style="margin-top: 28px; margin-bottom: 28px; font-size: 20px"
      >提交</van-button
    > -->
  </div>
</template>
<script>
import api from '@/api/task/index';
export default {
  data() {
    return {
      form: {
        auditResult: 0, //审核结果 4已阅 5退回
        id: null
      },
      examineState: 0,
      tongguo: 3,
      butongguo: 4,
      list: [
        {
          title: '任务编号',
          con: ''
        },
        {
          title: '任务标题',
          con: ''
        },
        {
          title: '接收单位',
          con: ''
        },
        {
          title: '查阅单位',
          con: ''
        }
      ],
      shixiang: [],
      zhixing: [
        {
          title: '运营单位',
          con: ''
        },
        {
          title: '人员姓名',
          con: ''
        },
        {
          title: '联系电话',
          con: ''
        }
      ],
      fankui: [
        {
          text: '1.滑道表面是否干燥、平整、坚实、光滑，有无霉变、杂物，滑道末端阻尼垫铺设是否正常。',
          state: 0
        },
        {
          text: '2.乘客滑行装备（含防护裤、手套、头盔等）有无破损、是否可正常使用。',
          state: 0
        }
      ],
      shenhejindu: [
        {
          step: '县文广旅体局: ',
          pass: 0,
          time: '10:10'
        },
        {
          step: '县城管局: ',
          pass: 1,
          time: '10:10'
        }
      ],
      xiangqingShow: false,
      activeIcon: require('@/assets/img/patrol/zhengchang.png'),
      inactiveIcon: require('@/assets/img/patrol/yichang.png'),
      xuanzhongIcon: require('@/assets/img/patrol/xuanzhong.png'),
      pass: require('@/assets/img/patrol/pass.png'),
      nopass: require('@/assets/img/patrol/nopass.png'),
      stepIcon: require('@/assets/img/patrol/stepIcon.png'),
      liuyanShow: false,
      cretime: '',
      id: {
        taskId: 0
      },
      Data: {}
    };
  },
  created() {
    this.id.taskId = this.$route.query.id;
    this.form.id = this.id.taskId;
    this.examineState = this.$route.query.examineState;
    let state = this.$route.query.state;
    if (state == '已审核') {
      this.xiangqingShow = true;
      this.getlist();
    } else if (state == '待审核') {
      this.xiangqingShow = false;
    } else {
      this.xiangqingShow = false;
    }
  },
  mounted() {
    this.getlist();
  },
  methods: {
    //退回
    back() {
      this.$router.push({
        name: 'back',
        query: {
          id: this.id.taskId
        }
      });
      this.form.auditResult = 4;
    },
    //已阅
    read() {
      this.form.auditResult = 3;
      api.auditTask(this.form).then((res) => {
        if (res.code == '200') {
          this.$toast('提交成功');
          this.$router.push({
            name: 'Task'
          });
        } else {
          this.$toast(res.message);
        }
      });
    },
    //获取审核详情
    getlist() {
      api.taskDetail(this.id).then((res) => {
        console.log(res.data);
        this.Data = res.data;
        this.list[0].con = this.Data.taskCode;
        this.list[1].con = this.Data.title;
        this.list[2].con = this.Data.companyName;
        this.list[3].con = this.Data.auditCompanyName;
        this.zhixing[1].con = this.Data.personPerson;
        this.zhixing[2].con = this.Data.mobile;
        this.zhixing[0].con = this.Data.companyName;
        let titleArr = this.Data.operateLogList;
        titleArr.splice(0, 2);
        this.Data.examineCompanyFlowList.map((item, index) => {
          return (
            (this.Data.examineCompanyFlowList[index].title =
              titleArr[index].title),
            (this.Data.examineCompanyFlowList[index].operateTime =
              titleArr[index].operateTime)
          );
        });
        console.log(this.Data.examineCompanyFlowList);
      });
    },

    liuyan() {
      this.liuyanShow = true;
    },
    noliuyan() {
      this.liuyanShow = false;
      console.log('1111');
    },
    onSubmit(values) {
      console.log('1111', values);
    }
  }
};
</script>
<style lang="less" scoped>
@import url("~@/assets/less/conment.less");

.FormBox {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;

  .jiben {
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .infomation {
      padding: 20px 15px 20px 10px;
      display: flex;
      justify-content: flex-start;
      position: relative;
      height: 32px;

      .kuai {
        width: 5px;
        height: 19px;
        background: #1c91ff;
        margin-top: 3px;
      }

      .xian {
        width: 90%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0px;
      }

      span {
        font-size: 19px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #363a44;
        margin-left: 10px;
        width: 90px;
      }
    }

    .list {
      // width: 100%;
      padding: 20px 15px;
      display: flex;
      position: relative;
      min-height: 32px;
      flex-direction: row;
      justify-content: space-between;

      // border-bottom: 1px solid #000;
      .title {
        min-width: 65px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
        white-space: nowrap;
      }

      .con {
        color: #363a44;
        font-size: 16px;
      }

      .xian {
        width: 90%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .fankui {
      height: 254px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;

      .fankuixiangqing {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #363a44;
        line-height: 23px;
      }

      .van-radio-group--horizontal {
        justify-content: center;
      }

      .img-icon {
        height: 16px;
      }

      .fankuiImg {
        display: flex;
        flex-direction: column;
        height: 110px;
        justify-content: space-between;

        .word {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #686b73;
        }

        img {
          width: 87px;
          height: 75px;
          border-radius: 5px;
          border: none;
        }
      }

      .xian {
        .hengxian();
      }
    }

    .result {
      height: 30px;
      display: flex;
      justify-content: space-between;
      .hezibj10px();
      position: relative;

      div {
        &:first-child {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #686b73;
        }

        &:nth-child(2) {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #363a44;
        }
      }

      .xian {
        .hengxian();
      }
    }

    .con {
      .liangbian();
      justify-content: flex-end;
      .hezibj10px();
      position: relative;

      span {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }

      div {
        width: 237px;
        height: 73px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #363a44;
      }

      .xian {
        .hengxian();
      }
    }

    .tu {
      span {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }

      padding: 20px 15px;
      .shangxia();

      .imgBox {
        margin: 10px 5px;
        display: flex;
        flex-wrap: wrap;

        img {
          width: 95px;
          height: 82px;
          margin: 0 5px;
          border-radius: 5px;
          margin-top: 10px;

        }
      }
    }

    .van-radio-group--horizontal {
      justify-content: center;
      padding: 37px 0px;
    }

    .img-icon {
      height: 18px;
    }

    .van-steps {
      .shenheqingkuang {
        height: 72px;
        background: #f6f7f8;
        border-radius: 8px;
        color: #686b73;
        font-size: 18px;
        .zhongjian();
        .liangbianfeng();

        span {
          color: #363a44;
        }

        img {
          width: 22px;
          height: 22px;
        }
      }

      .van-step--vertical:not(:last-child)::after {
        border-bottom-width: 0px;
      }
    }

    .rectify {
      flex-direction: column;
    }
  }

  .project {
    margin-top: 10px;
    height: 319px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    >.infomation {
      padding-left: 10px;
      padding-right: 15px;
      display: flex;
      justify-content: flex-start;
      position: relative;
      height: 32px;

      .kuai {
        width: 5px;
        height: 19px;
        background: #1c91ff;
        margin-top: 3px;
      }

      .xian {
        width: 90%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      span {
        font-size: 19px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #363a44;
        margin-left: 10px;
        width: 90px;
      }
    }

    .xiangqing {
      height: 165px;
      display: flex;
      justify-content: space-between;
      margin-left: 15px;
      margin-right: 15px;
      position: relative;

      .name {
        font-size: 16px;
        color: #686b73;
      }

      .con {
        width: 234px;
        font-size: 16px;
        color: #363a44;
        line-height: 23px;
        overflow: auto;
        margin-bottom: 5px;

        .shixiang {
          margin-top: 19px;

          &:first-child {
            margin-top: 0px;
          }
        }
      }

      .xian {
        width: 100%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .time {
      margin-left: 15px;
      margin-right: 15px;
      position: relative;
      height: 36px;
      display: flex;
      justify-content: space-between;
      color: #686b73;

      >.timer {
        align-self: flex-start;
        color: #363a44;
      }

      .xian {
        width: 100%;
        height: 1px;
        background: #e8e9ec;
        align-self: flex-end;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .bottomBox {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 36px;
    margin-bottom: 86px;

    .back {
      width: 49%;
      height: 50px;
      border: 1px solid #0085ff;
      border-radius: 25px;
      background: transparent;
      color: #0085ff;
    }

    .read {
      width: 49%;
      height: 50px;
      background: #0085ff;
      border-radius: 25px;
      border: none;
    }
  }
}

/deep/.van-radio {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #686b73;
}

/deep/.van-field__label {
  color: #686b73;
  font-size: 16px;
}

/deep/.van-field__control:disabled {
  font-size: 16px;
  -webkit-text-fill-color: #363a44;
  color: #363a44;
}

/deep/ .van-cell__value {
  border-radius: 10px;
  color: #363a44 !important;
  font-size: 16px;
}

/deep/.van-cell {
  padding: 20px 15px;
}

/deep/.van-cell::after {
  border-bottom: 1px #e8e9ec solid;
}
</style>
