import shouyejingqulist from '@/api/home/api_list'
import xuncha from '@/api/Patrol/api_list';
import my from '@/api/my/api_list'
import task from '@/api/task/api_list'
import waring from '@/api/waring/api_list'


export default {
  login: '/api/login/up',//登录
  ddlogin: '/api/app/user/getUserInfo',//钉钉登录code码
  ...shouyejingqulist,
  ...xuncha,
  ...my,
  ...task,
  ...waring
}