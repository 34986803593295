//判断是安卓还是IOS
export function checkSystem() {
  var u = window.navigator.userAgent, app = window.navigator.appVersion;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
  var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  // if (isAndroid) {
  //   return Android
  // }
  if (isIOS) {
    return true
  }
  return false;
}
