<template>
  <div>
    <div class="xiangxiBox" v-for="(item, index) in Data" :key="index">
      <div class="time">{{ item.createTime |datetimejs }}</div>
      <div class="gongao">
        <div class="title">
          <span>公告通知</span>
          <div class="xian"></div>
        </div>

        <div class="con">
          <span>{{ item.details}}</span>
        </div>
      </div>
    </div>
    <no-data v-if="Data.length==0"></no-data>
  </div>
</template>
<script>
import api from '@/api/my/index';
import request from '@/utils/request'
import noData from '@/components/no-data'

export default {
  components: {
    noData
  },
  data () {
    return {
      queryParams: {
        //1 全部 已读2,未读3
        type: 1,
        page: 1,
        pageSize: 100
      },
      Data: []
    };
  },
  mounted () {
    this.getGonggao();
  },
  methods: {
    getGonggao () {
      request
        .post('/announcement/list', this.queryParams)
        .then((res) => {
          if (res.code == '200' && res.success) {
            const { rows } = res.data
            this.Data = rows
          }
        })
    }
  }
};
</script>
<style lang="less" scoped>
@import url("~@/assets/less/conment.less");
.xiangxiBox {
  padding: 23px 10px 0px 10px;
  position: relative;
  .time {
    position: relative;
    height: 27px;
    background: #dddddd;
    color: #fff;
    border-radius: 14px;
    position: absolute;
    line-height: 27px;
    left: 50%;
    text-align: center;
    width: 45%;
    transform: translateX(-50%);
    font-size: 13px;
  }
  .gongao {
    // height: 224px;
    margin-top: 50px;
    background: #ffffff;
    border-radius: 11px;
    padding: 20px 20px;
    .title {
      height: 34px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #363a44;
      position: relative;
    }
    .xian {
      .hengxian();
      width: 100%;
    }
    .con {
      margin: 10px 0 0px 0;
      span {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #686b73;
      }
    }
  }
}
</style>
