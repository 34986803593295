import axios from 'axios'
var qs = require('qs')
import Vue from 'vue'
import router from '../router/index'
// let vm = new Vue;
const request = axios.create({
  baseURL: 'https://wenlv.borunspace.com:8001/api/', // 测试环境
  // baseURL: 'https://tlxyt.tonglu.gov.cn:82/home_api/', // 生产环境
  timeout: 1200000,
})

request.interceptors.request.use(
  (config) => {

    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const token = window.localStorage.getItem('token') || undefined
    // 请求接口带token
    if(token) {
      config.headers['token'] = token
    }
    if (config.method === 'get') {
      // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    return config
  },
  (error) => {
    return Promise.error(error)
  }
)

let isToken = true;

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    // console.log(response);
    if (response.status === 200) {
      if (response.data.code === 'A0002' || response.data.code === 'A0001') {
        if (isToken) {
          isToken = false;
          // vm.$message({
          //   message: response.data.message,
          //   type: "error",
          // });
          // Message.error(response.data.message)
          router.push('/')
          setTimeout(() => {
            isToken = true;
          }, 3000)
        }
        return
      }
      return Promise.resolve(response.data)
    } else {
      return Promise.reject(response.data)
    }
  },
  (error) => {
    console.log(error);
  }
)

export default request
