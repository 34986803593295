import api from './index';
// import request from "@/api/index";

export default {
  login(data) {
    return api.post2("login", data);
  },
  ddlogin(data) {
    return api.get('ddlogin', data)
  }
}